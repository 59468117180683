import { User } from 'shared/interface/User';
import { TokenError } from 'shared/interface/TokenError';
import { Token } from 'shared/interface/Token';
import { LoginFormProps } from '../../shared/interface/LoginFormProps';

export enum authActions {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_IS_AUTH = 'SET_IS_AUTH',
  UPDATE_USER_PROFILE_PHOTO = 'UPDATE_USER_PROFILE_PHOTO',
  UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER',
  GET_POSTS = 'GET_POSTS',
  GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER',
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  GET_INITIAL_DEALERSHIP_DEALER = 'GET_INITIAL_DEALERSHIP',
}

export const getInitDealership = (isUserDealerOrSalesRep?: boolean) => ({
  type: authActions.GET_INITIAL_DEALERSHIP_DEALER,
  payload: isUserDealerOrSalesRep,
});

export const setCurrentUser = (user: User) => ({
  type: authActions.SET_CURRENT_USER,
  payload: user,
});

export const updateCurrentUser = (user: User) => ({
  type: authActions.UPDATE_CURRENT_USER,
  payload: user,
});

export const setIsAuth = (isAuth: boolean) => ({
  type: authActions.SET_IS_AUTH,
  payload: isAuth,
});

export const updateUserProfilePhoto = (image: string) => ({
  type: authActions.UPDATE_USER_PROFILE_PHOTO,
  payload: image,
});

export const loginSagaAction = (
  data: LoginFormProps,
  onSuccess: (url: Token) => void,
  onFailed: (data: TokenError) => void
) => ({
  type: authActions.USER_LOGIN,
  payload: data,
  onSuccess,
  onFailed,
});

export const logoutSagaAction = (
  token: Token,
  onSuccess: () => void,
  redirect: () => void
) => ({
  type: authActions.USER_LOGOUT,
  payload: token,
  onSuccess,
  redirect,
});

export const getUserSagaAction = (onSuccess?: () => void) => ({
  type: authActions.GET_LOGGEDIN_USER,
  onSuccess,
});
