import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { OrderFilterValues } from 'order/interfaces/OrderFilterValues';

import {
  AssigneeOptionsEnum,
  OrderStatusEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import { searchCSRS } from 'order/components/OrderAssignees/store/orderAssigneesActions';

import { getFilteredDealerships } from 'overview/dealership/store/dealershipActions';

import SVG from 'shared/components/SVG';
import SearchInput from 'shared/components/SearchInput';
import UtilService from 'shared/services/util.service';
import { CsrQuotesTabsEnum } from 'shared/enum/CsrQuotesTabsEnum';
import { CsrTabsEnum } from 'shared/enum/CsrTabsEnum';
import { DealerQuotesTabsEnum } from 'shared/enum/DealerQuoteTabEnum';
import { DealerTabsEnum } from 'shared/enum/DealerTabsEnum';
import { Input } from 'shared/components/Input';
import { ReactComponent as ClearIcon } from 'assets/icons/select-close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter_list.svg';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { getProductLines } from 'shared/store/sharedActions';
import { silverChaliceTwo } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useInputSearch } from 'shared/hooks/useInputSearch';

import {
  RadioButtonNames,
  ShipWeekFilterType,
  ShipWeekFormValues,
  ShipWeekModal,
} from './ShipWeekModal';

import {
  TargetDateFilterType,
  TargetDateFormValues,
  TargetDateModal,
} from './TargetDateModal';

import { useHasPermissions } from '../../shared/hooks/useHasPermissions';
import { userPermissionsValues } from '../../shared/enum/userPermissionsEnum';

const Container = styled(Wrapper)`
  width: 100%;
  max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}px` : '190px')};
`;

const SearchContainer = styled(Wrapper)`
  width: 100%;
  max-width: 280px;
`;

const StyledInput = styled.div`
  position: relative;
  cursor: pointer;
  input {
    cursor: pointer;
  }
  svg {
    position: absolute;
    right: 13px;
    top: 13px;
  }
`;

const ClearFiltersButton = styled.button`
  border: none;
  background: none;
  font-size: 16px;
  color: ${({ theme }) => theme.lynch};
  cursor: pointer;

  button-solid:focus {
    border: none;
    outline: none;
  }
`;

const OrderTableFiltersContainer = styled(Wrapper)`
  position: relative;
  z-index: 6;
`;

export interface OrderTableFiltersProps {
  search: OrderFilterValues;
  setSearch: (value: OrderFilterValues) => void;
  isQuoteBoard?: boolean;
}

export const OrderTableFilters = ({
  search,
  setSearch,
  isQuoteBoard = false,
}: OrderTableFiltersProps) => {
  const dispatch = useAppDispatch();

  const productLines = useSelector(
    (state: RootState) => state.sharedReducer.productLines
  );

  const availableDealerships = useSelector(
    (state: RootState) => state.dealershipReducer.availableDealerships
  );

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserDealer = useHasPermissions(
    userPermissionsValues.DEALER_VIEW_ORDERBOARD
  );

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [shipWeekOpened, setShipWeekOpened] = useState(false);
  const [shipWeekText, setShipWeekText] = useState('');
  const [targetDateOpened, setTargetDateOpened] = useState(false);
  const [targetDateText, setTargetDateText] = useState('');

  const [query, onChange] = useInputSearch();

  const orderTypes = Object.keys(OrderTypeEnums)
    .filter(
      (k) =>
        Number.isNaN(+k) &&
        OrderTypeEnums[k as keyof typeof OrderTypeEnums] !==
          OrderTypeEnums.Quote
    )
    .map((key) => {
      return {
        label: key,
        value: OrderTypeEnums[key as keyof typeof OrderTypeEnums],
      };
    });

  const mapQuoteStatuses = () => {
    let statuses: SelectOptionProps[] = [];

    if (isUserCSR) {
      switch (search.tab) {
        case CsrQuotesTabsEnum['Not Submitted']:
          statuses = [
            ...statuses,
            {
              label: 'Not Submitted',
              value: OrderStatusEnums['Not Submitted'].toString(),
            },
          ];
          break;
        case CsrQuotesTabsEnum['Not Completed']:
          statuses = [
            ...statuses,
            {
              label: OrderStatusEnums[OrderStatusEnums.Received],
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums['Taken For Processing']],
              value: OrderStatusEnums['Taken For Processing'].toString(),
            },
          ];
          break;
        case CsrQuotesTabsEnum.Completed:
          statuses = [
            ...statuses,
            {
              label: OrderStatusEnums[OrderStatusEnums.Acknowledged],
              value: OrderStatusEnums.Acknowledged.toString(),
            },
          ];
          break;
        case CsrQuotesTabsEnum['All Quotes']:
          statuses = [
            ...statuses,
            {
              label: OrderStatusEnums[OrderStatusEnums.Received],
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums['Taken For Processing']],
              value: OrderStatusEnums['Taken For Processing'].toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums.Acknowledged],
              value: OrderStatusEnums.Acknowledged.toString(),
            },
          ];
          break;
        default:
          statuses = [];
      }
    } else {
      switch (search.tab) {
        case DealerQuotesTabsEnum['Not Submitted']:
          statuses = [
            ...statuses,
            {
              label: 'Not Submitted',
              value: OrderStatusEnums['Not Submitted'].toString(),
            },
          ];
          break;
        case DealerQuotesTabsEnum['Not Completed']:
          statuses = [
            ...statuses,
            {
              label: OrderStatusEnums[OrderStatusEnums.Received],
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums.Writing],
              value: OrderStatusEnums.Writing.toString(),
            },
          ];
          break;
        case DealerQuotesTabsEnum.Completed:
          statuses = [
            ...statuses,
            {
              label: OrderStatusEnums[OrderStatusEnums.Acknowledged],
              value: OrderStatusEnums.Acknowledged.toString(),
            },
          ];
          break;
        case DealerQuotesTabsEnum['All Quotes']:
          statuses = [
            ...statuses,
            {
              label: OrderStatusEnums[OrderStatusEnums['Not Submitted']],
              value: OrderStatusEnums['Not Submitted'].toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums.Received],
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums.Writing],
              value: OrderStatusEnums.Writing.toString(),
            },
            {
              label: OrderStatusEnums[OrderStatusEnums.Acknowledged],
              value: OrderStatusEnums.Acknowledged.toString(),
            },
          ];
          break;
        default:
          statuses = [];
      }
    }

    return statuses;
  };

  const mapOrderStatuses = () => {
    let statuses: SelectOptionProps[] = [];
    if (isUserCSR || isUserSalesRep) {
      switch (search.tab) {
        case CsrTabsEnum['Not Submitted']:
          statuses = [
            ...statuses,
            {
              label: 'Not Submitted',
              value: OrderStatusEnums['Not Submitted'].toString(),
            },
            {
              label: 'Canceled',
              value: OrderStatusEnums.Canceled.toString(),
            },
          ];
          break;
        case CsrTabsEnum['Orders to Write']:
          statuses = [
            ...statuses,
            {
              label: 'Received',
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: 'Taken For Processing',
              value: OrderStatusEnums['Taken For Processing'].toString(),
            },
          ];
          break;
        case CsrTabsEnum['Orders to 2nd Check']:
          statuses = [
            ...statuses,
            {
              label: 'Written',
              value: OrderStatusEnums.Written.toString(),
            },
          ];
          break;
        case CsrTabsEnum['Orders to Draw']:
          statuses = [
            ...statuses,
            {
              label: 'Second Checked',
              value: OrderStatusEnums['Second Checked'].toString(),
            },
            {
              label: 'Taken for Drawing',
              value: OrderStatusEnums['Taken For Drawing'].toString(),
            },
          ];
          break;
        case CsrTabsEnum['Orders to Acknowledge']:
          statuses = [
            ...statuses,
            {
              label: 'Drawing Completed',
              value: OrderStatusEnums['Drawing Completed'].toString(),
            },
          ];
          break;
        case CsrTabsEnum['Orders to Approve']:
          statuses = [
            ...statuses,
            {
              label: 'Acknowledged',
              value: OrderStatusEnums.Acknowledged.toString(),
            },
          ];
          break;
        case CsrTabsEnum['Approved Orders']:
          statuses = [
            ...statuses,
            {
              label: 'Approved',
              value: OrderStatusEnums.Approved.toString(),
            },
          ];
          break;
        case CsrTabsEnum['All Orders']:
          statuses = Object.keys(OrderStatusEnums)
            .filter(
              (k) =>
                Number.isNaN(+k) &&
                k !== 'Not Submitted' &&
                k !== 'Drawing' &&
                k !== 'Writing' &&
                k !== 'Canceled' &&
                k !== 'Released'
            )
            .map((key) => {
              return {
                label: key,
                value:
                  OrderStatusEnums[
                    key as keyof typeof OrderStatusEnums
                  ].toString(),
              };
            }) as SelectOptionProps[];
          break;
        default:
          statuses = [];
      }
    } else {
      switch (search.tab) {
        case DealerTabsEnum['Not Submitted']:
          statuses = [
            ...statuses,
            {
              label: 'Not Submitted',
              value: OrderStatusEnums['Not Submitted'].toString(),
            },
            {
              label: 'Canceled',
              value: OrderStatusEnums.Canceled.toString(),
            },
          ];
          break;
        case DealerTabsEnum.Submitted:
          statuses = [
            ...statuses,
            {
              label: 'Received',
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: 'Writing',
              value: OrderStatusEnums.Writing.toString(),
            },
            {
              label: 'Drawing',
              value: OrderStatusEnums.Drawing.toString(),
            },
            {
              label: 'Acknowledged',
              value: OrderStatusEnums.Acknowledged.toString(),
            },
          ];
          break;
        case DealerTabsEnum['Approved Acknowledgements']:
          statuses = [
            ...statuses,
            {
              label: 'Approved',
              value: OrderStatusEnums.Approved.toString(),
            },
          ];
          break;
        case DealerTabsEnum['All Orders']:
          statuses = [
            ...statuses,
            {
              label: 'Not Submitted',
              value: OrderStatusEnums['Not Submitted'].toString(),
            },
            {
              label: 'Received',
              value: OrderStatusEnums.Received.toString(),
            },
            {
              label: 'Writing',
              value: OrderStatusEnums.Writing.toString(),
            },
            {
              label: 'Drawing',
              value: OrderStatusEnums.Drawing.toString(),
            },
            {
              label: 'Acknowledged',
              value: OrderStatusEnums.Acknowledged.toString(),
            },
            {
              label: 'Approved',
              value: OrderStatusEnums.Approved.toString(),
            },
            {
              label: isUserDealer ? 'Released' : 'Tech Edit Started',
              value: isUserDealer
                ? OrderStatusEnums.Released.toString()
                : OrderStatusEnums['Tech Edit Started'].toString(),
            },
            {
              label: 'Canceled',
              value: OrderStatusEnums.Canceled.toString(),
            },
            {
              label: 'Invoiced',
              value: OrderStatusEnums.Invoiced.toString(),
            },
          ];
          break;
        default:
          statuses = [];
      }
    }
    return statuses;
  };

  const productLineOptions = productLines?.map((pl) => {
    return {
      label: pl.name,
      value: pl.id,
    };
  });

  const dealerships = useSelector(
    (state: RootState) => state.dealershipReducer.filteredDealerships
  );

  const assignees = useSelector(
    (state: RootState) => state.orderAssigneesReducer.searchedCSRs
  );

  useEffect(() => {
    dispatch(getProductLines());
  }, []);

  useEffect(() => {
    if (isUserCSR) {
      dispatch(getFilteredDealerships());
    }

    if (isUserCSR || isUserSalesRep) {
      dispatch(searchCSRS({ searchTerm: '' }));
    }
  }, [isUserCSR, isUserSalesRep]);

  useEffect(() => {
    if (search.shipWeekFilterType) {
      let text = [search.shipWeek?.label, search.endShipWeek?.label]
        .filter((x) => x)
        .join(' and ');

      if (search.shipWeekFilterType === ShipWeekFilterType.asap) {
        text = RadioButtonNames.ASAP;
      }

      setShipWeekText(text);
    }

    if (search.targetDateFilterType) {
      let text = '';
      if (search.targetDate) {
        text = UtilService.formatDate({
          date: new Date(search.targetDate).toDateString(),
          asElement: false,
          withTime: false,
        }) as string;
      }
      if (search.endTargetDate) {
        text += ` and ${
          UtilService.formatDate({
            date: new Date(search.endTargetDate).toDateString(),
            asElement: false,
            withTime: false,
          }) as string
        }`;
      }
      setTargetDateText(text);
    }

    if (search.order && searchInputRef.current) {
      searchInputRef.current.value = search.order;
      onChange(search.order);
    }
  }, [search]);

  const onSearchHandler = (searchTerm: string) =>
    setSearch({
      ...search,
      order: searchTerm,
      page: 1,
    });

  const onOrderTypeChangeHandler = (option: SelectOptionProps) => {
    setSearch({
      ...search,
      orderType: option,
      page: 1,
    });
  };

  const onStatusChangeHandler = (option: SelectOptionProps) =>
    setSearch({
      ...search,
      status: option,
      page: 1,
    });

  const onProductLineChangeHandler = (option: SelectOptionProps) =>
    setSearch({
      ...search,
      productLine: option,
      page: 1,
    });

  const onShipWeekSubmitHandler = (form: ShipWeekFormValues) => {
    setShipWeekOpened(false);

    let text = [form.shipWeek?.label, form.endShipWeek?.label]
      .filter((x) => x)
      .join(' and ');

    if (
      ShipWeekFilterType[
        form.shipWeekOption as keyof typeof RadioButtonNames as keyof typeof ShipWeekFilterType
      ] === ShipWeekFilterType.asap
    ) {
      text = RadioButtonNames.ASAP;
    }

    setShipWeekText(text);

    setSearch({
      ...search,
      shipWeek: form.shipWeek,
      endShipWeek: form.endShipWeek,
      page: 1,
      shipWeekFilterType:
        ShipWeekFilterType[
          form.shipWeekOption as keyof typeof RadioButtonNames as keyof typeof ShipWeekFilterType
        ],
    });
  };

  const onShipWeekClickHandler = () => {
    if (shipWeekText) {
      setShipWeekText('');
      setSearch({
        ...search,
        shipWeek: null,
        endShipWeek: null,
        shipWeekFilterType: null,
      });
    } else {
      setShipWeekOpened(true);
    }
  };

  const clearFilters = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
    setShipWeekText('');
    setTargetDateText('');
    setSearch({
      ...search,
      order: '',
      orderType: null,
      status: null,
      productLine: null,
      shipWeek: null,
      endShipWeek: null,
      targetDate: null,
      dealership: null,
      assignee: null,
      endTargetDate: null,
      page: 1,
      shipWeekFilterType: null,
    });
  };

  const onDealershipChangeHandler = (option: SelectOptionProps) =>
    setSearch({
      ...search,
      dealership: option,
      page: 1,
    });

  const onAssigneeChangeHandler = (option: SelectOptionProps) =>
    setSearch({
      ...search,
      assignee: option,
      page: 1,
    });

  const onTargetDateClickHandler = () => {
    if (targetDateText) {
      setTargetDateText('');
      setSearch({
        ...search,
        targetDate: null,
        endTargetDate: null,
        page: 1,
        targetDateFilterType: null,
      });
    } else {
      setTargetDateOpened(true);
    }
  };

  const onTargetDateSubmitHandler = (form: TargetDateFormValues) => {
    setTargetDateOpened(false);
    let text = '';
    if (form.targetDate) {
      text = UtilService.formatDate({
        date: form.targetDate.toDateString(),
        asElement: false,
        withTime: false,
      }) as string;
    }
    if (form.endTargetDate) {
      text += ` and ${
        UtilService.formatDate({
          date: form.endTargetDate.toDateString(),
          asElement: false,
          withTime: false,
        }) as string
      }`;
    }
    setTargetDateText(text);

    setSearch({
      ...search,
      targetDate: form.targetDate,
      endTargetDate: form.endTargetDate,
      page: 1,
      targetDateFilterType:
        TargetDateFilterType[
          form.targetDateOption as keyof typeof RadioButtonNames as keyof typeof TargetDateFilterType
        ],
    });
  };

  const assigneeOptions = () => {
    const assigneOptionsItems = UtilService.mapUsersToSelectOptions(assignees);

    assigneOptionsItems?.unshift({
      label: 'Unassigned',
      value: AssigneeOptionsEnum.UNASSIGNED,
    });

    return assigneOptionsItems;
  };

  const getDealershipOptions = () => {
    if (isUserCSR) {
      return UtilService.mapObjectToSelectOptions(
        dealerships.sort((a, b) => UtilService.sortBy(a.name, b.name))
      );
    }

    if (isUserSalesRep) {
      return UtilService.mapObjectToSelectOptions(
        availableDealerships.sort((a, b) => UtilService.sortBy(a.name, b.name)),
        'id',
        'name'
      );
    }
    return [];
  };

  const isNotSubmittedTab = UtilService.isNotSubmittedTab(
    isUserCSR || isUserSalesRep,
    search
  );

  useEffect(() => {
    onSearchHandler(query);
  }, [query]);

  return (
    <OrderTableFiltersContainer flexGrow>
      <Wrapper flex between middle>
        <div>
          <SearchContainer>
            <SearchInput
              data-role="search"
              ref={searchInputRef}
              data-loading={false}
              placeholder={`Search for ${
                isQuoteBoard ? 'a quote' : 'an order'
              }`}
              onSearchQueryChanged={(e) => onChange(e.target.value)}
            />
          </SearchContainer>
        </div>
        <div>
          <ClearFiltersButton onClick={clearFilters}>
            Clear filters
          </ClearFiltersButton>
        </div>
      </Wrapper>

      <Spacer h="16px" />

      <Wrapper flex flexWrap gap={10}>
        {!isNotSubmittedTab && (
          <Container maxWidth={search.endShipWeek ? 290 : 190}>
            <StyledInput onClick={onShipWeekClickHandler}>
              <Input placeholder="Ship week" defaultValue={shipWeekText} />

              <SVG
                icon={
                  shipWeekText ? (
                    <ClearIcon
                      style={{
                        background: '#677D9E',
                        borderRadius: '50%',
                        fill: 'white',
                      }}
                    />
                  ) : (
                    <FilterIcon fill={silverChaliceTwo} />
                  )
                }
              />
            </StyledInput>
          </Container>
        )}

        {UtilService.canSeeTargetDateFilter(isUserCSR, search) && (
          <Container maxWidth={search.endTargetDate ? 290 : 190}>
            <StyledInput onClick={onTargetDateClickHandler}>
              <Input placeholder="Target Date" defaultValue={targetDateText} />
              <SVG
                icon={
                  targetDateText ? (
                    <ClearIcon
                      style={{
                        background: '#677D9E',
                        borderRadius: '50%',
                        fill: 'white',
                      }}
                    />
                  ) : (
                    <FilterIcon fill={silverChaliceTwo} />
                  )
                }
              />
            </StyledInput>
          </Container>
        )}

        {!isQuoteBoard && (
          <Container>
            <Select
              placeholder="Order Type"
              options={orderTypes.sort((a, b) =>
                UtilService.sortBy(a.label, b.label)
              )}
              onChange={onOrderTypeChangeHandler}
              isClearable
              value={search.orderType}
            />
          </Container>
        )}

        <Container>
          <Select
            placeholder="Status"
            options={(isQuoteBoard ? mapQuoteStatuses : mapOrderStatuses)()}
            onChange={onStatusChangeHandler}
            isClearable
            value={search.status ?? null}
          />
        </Container>

        {(isUserCSR || isUserSalesRep) && (
          <Container>
            <Select
              placeholder="Dealership"
              options={getDealershipOptions()}
              onChange={onDealershipChangeHandler}
              isClearable
              value={search.dealership}
            />
          </Container>
        )}

        {(isUserCSR || isUserSalesRep) && (
          <>
            <Container>
              <Select
                placeholder="Product Line"
                options={productLineOptions?.sort((a, b) =>
                  UtilService.sortBy(a.label, b.label)
                )}
                onChange={onProductLineChangeHandler}
                isClearable
                value={search.productLine}
              />
            </Container>

            <Container>
              <Select
                placeholder="Assignee"
                options={assigneeOptions()?.sort((a, b) =>
                  UtilService.sortBy(a.label, b.label)
                )}
                onChange={onAssigneeChangeHandler}
                isClearable
                isDisabled={!assignees?.length}
                value={search.assignee}
              />
            </Container>
          </>
        )}
      </Wrapper>

      <ShipWeekModal
        opened={shipWeekOpened}
        cancel={() => setShipWeekOpened(false)}
        onSubmit={onShipWeekSubmitHandler}
      />

      <TargetDateModal
        opened={targetDateOpened}
        cancel={() => setTargetDateOpened(false)}
        onSubmit={onTargetDateSubmitHandler}
      />
    </OrderTableFiltersContainer>
  );
};
