export enum FinishedEndType {
  'Base' = 1,
  'Wall' = 2,
  'Tall' = 3,
  'No Midrail Cabinets' = 4,
}

export enum UpchargeType {
  'Wood Upcharge' = 1,
  'Wood Special Effect',
  'Finish Upcharge',
  'Finish Special Effect',
  'One Inch Thick Door',
  'Icon Face Frame',
  'Finish Impression',
  'Cabinet Box Material',
  'List Discount',
  'Varnish Upcharge',
  // inovae 2.0 types - non-existing on BE but needed for printing out correct label of upcharge row
  'Material Group Upcharge',
  'Color Upcharge',
}

export enum PremiumSelectionType {
  'Walnut Drawer Boxes' = 1,
  'Walnut Ast Boxes',
  'Door Hardware',
  'Drawer Hardware',
  'Hinges',
  'Luxe Drawer Boxes',
  'Luxe Ast Boxes',
  'Legra Drawer Boxes',
  'Legra Ast Boxes',
  'Leg Levelers',
}

export enum NetUpchargeType {
  'Display/Personal Discount' = 1,
  'Net Discount',
  'Design/Draft Fee',
}

export interface ModificationsPriceReview {
  id: string;
  quantity: number;
  name: string;
  description: string;
  note: string;
  additionalNote: string;

  // prices catalog
  catalogListPrice: number;
  catalogNetPrice: number;
  catalogNetUnitPrice: number;
  catalogUnitPrice: number;

  // prices dealer
  listPrice: number;
  netPrice: number;
  netUnitPrice: number;
  unitPrice: number;

  isDeleted?: boolean;

  squareFootParts: boolean;
}

export interface DoorCodePrice {
  code: string;
  upchargeReason: string | null;
  upcharge: number | null;
  upchargeQuantity: number;

  catalogListPrice: number;
  catalogNetPrice: number;
  catalogQuantity: number;
  catalogNetUnitPrice: number;
  catalogUnitPrice: number;

  listPrice: number;
  netPrice: number;
  unitPrice: number;
  netUnitPrice: number;
  quantity: number;

  lineItemQuantity: number;

  squareFootTotal: null | number;
}

export interface Hardware {
  catalogDoorHardwareCount: number;
  catalogDrawerHardwareCount: number;
  catalogHingeCount: number;
  doorHardwareCount: number;
  drawerHardwareCount: number;
  hingeCount: number;
}

export interface DoorCodesPriceReview {
  wall: DoorCodePrice[];
  base: DoorCodePrice[];
  drawer: DoorCodePrice[];
  hardware: Hardware;
}

export interface FinishedEndsPriceReview {
  type: FinishedEndType;
  quantity: number;
  unitPrice: number;
  netUnitPrice: number;
  listPrice: number;
  netPrice: number;
}

export interface UpchargesPriceReview {
  upchargeType: UpchargeType;

  catalogListPrice: number;
  catalogNetPrice: number;
  catalogPercentage: number;

  netPrice: number;
  percentage: number;
  listPrice: number;

  isDeleted?: boolean;
}

export interface NetUpchargesPriceReview {
  type: NetUpchargeType;
  netPrice: number;
  percentage: number;
  listPrice: number;
}

export interface PremiumSelectionPriceReview {
  premiumSelectionType: PremiumSelectionType;
  catalogOptionLabel: string;

  // catalog prices
  catalogListPrice: number;
  catalogNetPrice: number;
  catalogUnitPrice: number;
  catalogQuantity: number;
  catalogNetUnitPrice: number;

  // dealer prices
  listPrice: number;
  netPrice: number;
  netUnitPrice: number;
  quantity: number;
  unitPrice: number;
}

export interface LineItemPriceReview {
  lineItemId: string;
  code: string;
  clientLineItemNumber: number | null;
  number: number;
  quantity: number;
  hingePosition: number;
  finishEndPosition: number;
  exposedEndPosition: number;
  type: string;

  unitPrice: number;
  listPrice: number;
  netUnitPrice: number;
  netPrice: number;

  catalogUnitPrice: number;
  catalogListPrice: number;
  catalogNetUnitPrice: number;
  catalogNetPrice: number;
  upchargeExcluded: boolean;

  width: number;
  height: number;
  depth: number;

  dimUp: string;
  catalogDimUp: string;
  catalogDimUpUnitPrice: number;
  catalogDimUpListPrice: number;
  catalogDimUpNetUnitPrice: number;
  catalogDimUpNetPrice: number;
  dimUpUnitPrice: number;
  dimUpListPrice: number;
  dimUpNetUnitPrice: number;
  dimUpNetPrice: number;

  woodInteriorUpcharge: number;
  catalogWoodInteriorUpchargeUnitPrice: number;
  catalogWoodInteriorUpchargeListPrice: number;
  catalogWoodInteriorUpchargeNetUnitPrice: number;
  catalogWoodInteriorUpchargeNetPrice: number;
  woodInteriorUpchargeUnitPrice: number;
  woodInteriorUpchargeListPrice: number;
  woodInteriorUpchargeNetUnitPrice: number;
  woodInteriorUpchargeNetPrice: number;

  modificationsPrice: ModificationsPriceReview[];
  doorCodes: DoorCodesPriceReview;

  isDeleted: boolean;

  acknowledgementName: string;

  squareFootTotal: null | number;

  rightLegWidth: number;
  rightLegDepth: number;

  pwwNumber: string;
}

export interface OrderStylePriceReview {
  finishedEndsPriceReview: FinishedEndsPriceReview[];
  lineItemPriceReview: LineItemPriceReview[];
  premiumSelectionPriceReview: PremiumSelectionPriceReview[];
  upchargesPriceReview: UpchargesPriceReview[];
  netUpchargesDiscountsPriceReview: NetUpchargesPriceReview[];

  doorCodesPriceReview: DoorCodesPriceReview;
  priceSchedule: string;

  styleId: string;
  styleNote: string;

  freight: number;
  totalFreight: number;

  catalogLineItemsListTotal: number;
  catalogLineItemsNetTotal: number;
  lineItemsListTotal: number;
  lineItemsNetTotal: number;

  catalogExcludedListSum: number;
  catalogExcludedNetSum: number;
  excludedListSum: number;
  excludedNetSum: number;

  catalogExcludedListTotal: number;
  catalogExcludedNetTotal: number;
  excludedListTotal: number;
  excludedNetTotal: number;

  finishedEndsListTotal: number;
  finishedEndsNetTotal: number;

  upchargesListTotal: number;
  upchargesNetTotal: number;
  catalogUpchargesListTotal: number;
  catalogUpchargesNetTotal: number;

  premiumSelectionListTotal: number;
  premiumSelectionNetTotal: number;
  catalogPremiumSelectionListTotal: number;
  catalogPremiumSelectionNetTotal: number;

  doorCodesListTotal: number;
  doorCodesNetTotal: number;
  catalogDoorCodesListTotal: number;
  catalogDoorCodesNetTotal: number;

  totalListSum: number;
  totalNetSum: number;
  catalogTotalListSum: number;
  catalogTotalNetSum: number;

  totalListPrice: number;
  totalNetPrice: number;
  catalogTotalListPrice: number;
  catalogTotalNetPrice: number;

  netUpchargesDiscountsTotalPriceForList: number;
  netUpchargesDiscountsTotalPriceForNet: number;
  noCharge: boolean;
  noFreight: boolean;
  totalListPriceNoChargeForList: number;
  totalListPriceNoChargeForNet: number;
  totalListPriceForList: number;
  totalListPriceForNet: number;
  workOrderNumber: string;
}

export interface OrderPriceReview {
  orderStylePriceReview: OrderStylePriceReview[];

  freight: number;
  fuelSurchargePercentage: number;
  grossMargin: number;
  jobSite: number;
  salesTax: number;

  catalogTotalListPrice: number;
  catalogTotalNetPrice: number;

  totalListPrice: number;
  totalNetPrice: number;

  totalListFreight: number;
  totalNetFreight: number;

  fuelListSurcharge: number;
  fuelNetSurcharge: number;

  totalListCost: number;
  totalNetCost: number;

  paymentTermsCode: string;

  totalExcludedNetPrice: number;
}

export interface OrderPriceReviewSM
  extends Pick<
    OrderPriceReview,
    'paymentTermsCode' | 'totalNetPrice' | 'totalNetFreight' | 'totalNetCost'
  > {
  lineItems: LineItemPriceReview[];
  netDiscountPercentageForNet: number;
  noCharge: boolean;
  noFreight: boolean;
  totalNetDiscountForNet: number;
  totalListPriceNoChargeForNet: number;
  totalListPriceForNet: number;
}
