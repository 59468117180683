import { ACKStatusEnum } from 'order/wizard/orderAcknowledgement/enums/ACKStatusEnum';
import { Collaborator } from 'order/interfaces/Collaborator';
import { CreateJobRequest, GetJobRequest, Job } from 'order/interfaces/Job';
import { GradingOption } from 'order/enums/orderEnums';
import { IOrderDeleteStyleRequest } from 'order/interfaces/IOrderDeleteStyleRequest';
import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderCollaboratorsRequest } from 'order/interfaces/OrderCollaboratorsRequest';
import { OrderExistingShippingAddresRequest } from 'order/interfaces/OrderShippingAddress';
import { OrderStylesAdjustments } from 'order/wizard/orderStyles/interface/Adjustments';
import { Priority } from 'order/enums/priorityEnum';
import { ScheduleType } from 'order/enums/scheduleType';
import { SearchCollaboratosRequest } from 'order/interfaces/SearchCollaborators';
import { SpecialOrderApproval } from 'order/wizard/orderAcknowledgement/interface/SpecialOrderApproval';
import { SpecialOrderApprovalStatusEnum } from 'order/wizard/orderAcknowledgement/enums/SpecialOrderApprovalStatusEnum';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { UpchargeDifference } from 'order/interfaces/UpchargeDifference';

import {
  OrderPriceReviewSM,
  OrderStylePriceReview,
} from 'order/wizard/orderStyles/interface/OrderPriceReview';

import {
  Order,
  OrderLockInfo,
  OrderStatusEditLog,
} from 'order/interfaces/Order';

import { BaseField } from 'shared/interface/BaseField';
import { CustomAddressRequestObject } from 'shared/interface/CustomAddressRequestObject';
import { Dealership } from 'shared/interface/Dealership';
import { FileUploadResponse } from 'shared/interface/serverResponses/FileUploadResponse';
import { IFileData } from 'shared/interface/IFile';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { User } from 'shared/interface/User';

import { OrderInitialStore } from './orderReducer';

export enum orderActions {
  ADD_NEW_JOB = 'ADD_NEW_JOB',
  CLEAR_ORDER = 'CLEAR_ORDER',
  CREATE_ORDER = 'CREATE_ORDER',
  CREATE_ORDER_JOB = 'CREATE_ORDER_JOB',
  DELETE_ORDER = 'DELETE_ORDER',
  GET_ORDER = 'GET_ORDER',
  GET_ORDER_JOB = 'GET_ORDER_JOB',
  GET_ORDER_JOBS = 'GET_ORDER_JOBS',
  SET_JOBS = 'SET_JOBS',
  SET_ORDER = 'SET_ORDER',
  UPDATE_ORDER = 'UPDATE_ORDER',
  GET_ORDER_STATUS = 'GET_ORDER_STATUS',
  SET_ORDER_STATUS = 'SET_ORDER_STATUS',
  UPDATE_SCHEDULE_TYPE = 'UPDATE_SCHEDULE_TYPE',

  CHANGE_COLLABORATOR_TYPE = 'CHANGE_COLLABORATOR_TYPE',
  CLEAR_SELECTED_COLLABORATORS = 'CLEAR_SELECTED_COLLABORATORS',
  SET_SELECTED_COLLABORATORS = 'SET_SELECTED_COLLABORATORS',
  DESELECT_COLLABORATOR = 'DESELECT_COLLABORATOR',
  REMOVE_ORDER_COLLABORATOR = 'REMOVE_ORDER_COLLABORATOR',
  SAVE_ORDER_COLLABORATORS = 'SAVE_ORDER_COLLABORATORS',
  SEARCH_COLLABORATORS = 'SEARCH_COLLABORATORS',
  SELECT_COLLABORATOR = 'SELECT_COLLABORATOR',
  UPDATE_SELECTED_COLLABORATOR = 'UPDATE_SELECTED_COLLABORATOR',
  SET_SEARCHED_COLLABORATORS = 'SET_SEARCHED_COLLABORATORS',
  REMOVE_DEALER_COLLABORATORS = 'REMOVE_DEALER_COLLABORATORS',

  SAVE_ORDER_EXISTING_SHIPPING_ADDRESS = 'SAVE_ORDER_EXISTING_SHIPPING_ADDRESS',
  SET_ORDER_NON_EXISTING_SHIPPING_ADDRESS = 'SET_ORDER_NON_EXISTING_SHIPPING_ADDRESS',
  CREATE_NON_EXISTING_SHIPPING_ADDRESS = 'CREATE_NON_EXISTING_SHIPPING_ADDRESS',
  CLEAR_NON_EXISTING_SHIPPING_ADDRESS = 'CLEAR_NON_EXISTING_SHIPPING_ADDRESS',
  ADD_NEW_STYLE = 'ADD_NEW_STYLE',
  GET_ORDER_STYLES = 'GET_ORDER_STYLES',
  SET_ORDER_STYLES = 'SET_ORDER_STYLES',
  GET_ORDER_STYLE = 'GET_ORDER_STYLE',
  DELETE_ORDER_STYLE = 'DELETE_ORDER_STYLE',
  UPLOAD_SINGLE_FILE = 'UPLOAD_SINGLE_FILE',
  GET_ORDER_LINE_ITEMS = 'GET_ORDER_LINE_ITEMS',
  SET_ORDER_LINE_ITEMS = 'SET_ORDER_LINE_ITEMS',
  FILTER_DELETED_ORDER_LINE_ITEM = 'FILTER_DELETED_ORDER_LINE_ITEM',

  GET_ORDER_LINE_ITEM_CL_VALUES = 'GET_ORDER_LINE_ITEM_CL_VALUES',
  UPDATE_ORDER_LINE_ITEM_CL_VALUES = 'UPDATE_ORDER_LINE_ITEM_CL_VALUES',

  GET_ORDER_PRICE_REVIEW = 'GET_ORDER_PRICE_REVIEW',
  SET_ORDER_PRICE_REVIEW = 'SET_ORDER_PRICE_REVIEW',

  GET_ORDER_PRICE_REVIEW_SM = 'GET_ORDER_PRICE_REVIEW_SM',
  SET_ORDER_PRICE_REVIEW_SM = 'SET_ORDER_PRICE_REVIEW_SM',

  SET_STYLE_PRIORITY = 'SET_STYLE_PRIORITY',
  SET_LINEITEM_PRIORITY = 'SET_LINEITEM_PRIORITY',
  SET_ORDER_PRIORITY = 'SET_ORDER_PRIORITY',
  UPDATE_LINE_ITEM_NO_OF_TOPICS = 'UPDATE_LINE_ITEM_NO_OF_TOPICS',
  UPDATE_STYLE_NO_OF_TOPICS = 'UPDATE_STYLE_NO_OF_TOPICS',
  SUBMIT_ORDER = 'SUBMIT_ORDER',
  GET_UNRESOLVED_TOPICS = 'GET_UNRESOLVED_TOPICS',
  SET_UNRESOLVED_TOPICS = 'SET_UNRESOLVED_TOPICS',

  GET_DEALERSHIP_PREFERENCES = 'GET_DEALERSHIP_PREFERENCES',
  SET_DEALERSHIP_PREFERENCES = 'SET_DEALERSHIP_PREFERENCES',

  GET_SHIP_VIA_OPTIONS = 'GET_SHIP_VIA_OPTIONS',
  SAVE_SHIP_VIA_OPTION = 'SAVE_SHIP_VIA_OPTION',

  CHANGE_ORDER_GRADE = 'CHANGE_ORDER_GRADE',

  UPDATE_LINE_ITEM_CL = 'UPDATE_LINE_ITEM_CL',
  UPDATE_LINE_ITEM_L = 'UPDATE_LINE_ITEM_L',

  LOCK_ORDER_FOR_EDIT = 'LOCK_ORDER_FOR_EDIT',
  UNLOCK_ORDER_FOR_EDIT = 'UNLOCK_ORDER_FOR_EDIT',

  GET_LOCK_INFO_ON_ORDER = 'GET_LOCK_INFO_ON_ORDER',
  SET_LOCK_INFO_ON_ORDER = 'SET_LOCK_INFO_ON_ORDER',

  SET_CAN_EDIT_ORDER = 'SET_CAN_EDIT_ORDER',

  KEEP_ALIVE_ORDER_EDIT_SESSION = 'KEEP_ALIVE_ORDER_EDIT_SESSION',

  UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS',
  REPLACE_ORDER_STATUS = 'REPLACE_ORDER_STATUS',

  GET_STATUS_EDIT_LOG = 'GET_STATUS_EDIT_LOG',
  SET_STATUS_EDIT_LOG = 'SET_STATUS_EDIT_LOG',

  STYLE_CANCEL_REQUEST = 'STYLE_CANCEL_REQUEST',
  SET_STYLE_CANCEL_REQUEST = 'SET_STYLE_CANCEL_REQUEST',

  UPDATE_ORDER_ACK_STATUS = 'UPDATE_ORDER_ACK_STATUS',
  UPDATE_ORDER_ACK_ID = 'UPDATE_ORDER_ACK_ID',

  SET_CURRENT_USER_COLLABORATOR_ACCESS = 'SET_CURRENT_USER_COLLABORATOR_ACCESS',

  SET_ORDER_IS_VALID = 'SET_ORDER_IS_VALID',
  SET_ORDER_IS_LOCKED = 'SET_ORDER_IS_LOCKED',

  // SPECIAL ORDER APPROVAL
  SET_SPECIAL_ORDER_APPROVAL = 'SET_SPECIAL_ORDER_APPROVAL',
  UPDATE_SPECIAL_ORDER_APPROVAL_STATUS = 'UPDATE_SPECIAL_ORDER_APPROVAL_STATUS',

  APPROVE_SPECIAL_ORDER_APPROVAL = 'APPROVE_SPECIAL_ORDER_APPROVAL',

  CHANGES_REQUESTED_SPECIAL_ORDER_APPROVAL = 'CHANGES_REQUESTED_SPECIAL_ORDER_APPROVAL',
  CHANGES_REQUESTED_SPECIAL_ORDER_APPROVAL_FINISH = 'CHANGES_REQUESTED_SPECIAL_ORDER_APPROVAL_FINISH',

  SET_ADJUSTMENT_DATA = 'SET_ADJUSTMENT_DATA',

  GET_DEALERSHIPS = 'GET_DEALERSHIPS',
  SET_DEALERSHIPS = 'SET_DEALERSHIPS',
  GET_DEALERSHIP_USERS = 'GET_DEALERSHIP_USERS',
  SET_DEALERSHIP_USERS = 'SET_DEALERSHIP_USERS',
  CLEAR_DEALERSHIP_USERS = 'CLEAR_DEALERSHIP_USERS',
  SET_SHOULD_NAVIGATE_TO_TOPIC = 'SET_SHOULD_NAVIGATE_TO_TOPIC',

  SET_ASSIGNEES_ON_ORDER = 'SET_ASSIGNEES_ON_ORDER',

  GET_UPCHARGE_DIFFERENCES = 'GET_UPCHARGE_DIFFERENCES',
  SET_UPCHARGE_DIFFERENCES = 'SET_UPCHARGE_DIFFERENCES',
  SET_UPCHARGE_DIFFERENCES_MODAL_OPENED = 'SET_UPCHARGE_DIFFERENCES_MODAL_OPENED',

  OVERRIDE_UPCHARGES = 'OVERRIDE_UPCHARGES',

  UPCHARGES_OVERRIDEN = 'UPCHARGES_OVERRIDEN',

  EXPORT_AS_PDF = 'EXPORT_AS_PDF',

  GET_SHIPPING_ADDRESS = 'GET_SHIPPING_ADDRESS',
  SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS',

  UPDATE_LINE_ITEM_NO_OF_UNREAD_TOPICS = 'UPDATE_LINE_ITEM_NO_OF_UNREAD_TOPICS',
  UPDATE_STYLE_NO_OF_UNREAD_TOPICS = 'UPDATE_STYLE_NO_OF_UNREAD_TOPICS',
}

export const clearOrder = () => ({
  type: orderActions.CLEAR_ORDER,
});

export const getOrderJobs = (
  payload: GetJobRequest,
  onSuccess: (jobs: SelectOptionProps[]) => void
) => ({
  type: orderActions.GET_ORDER_JOBS,
  payload,
  onSuccess,
});

export const getOrderJob = (jobId: string, onSuccess?: (job: Job) => void) => ({
  type: orderActions.GET_ORDER_JOB,
  payload: jobId,
  onSuccess,
});

export const createOrderJob = (
  payload: CreateJobRequest,
  onSuccess: (jobId: string) => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.CREATE_ORDER_JOB,
  payload,
  onSuccess,
  onFailed,
});

export const addNewJob = (job: Job) => ({
  type: orderActions.ADD_NEW_JOB,
  payload: job,
});

export const setJobs = (jobs: PaginatedItems<Job> | null, append: boolean) => ({
  type: orderActions.SET_JOBS,
  payload: { jobs, append },
});

export const createOrder = (
  payload: Order,
  onSuccess: (orderId: string) => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.CREATE_ORDER,
  payload,
  onSuccess,
  onFailed,
});

export const updateOrder = (
  payload: Order,
  onSuccess: (orderId: string) => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.UPDATE_ORDER,
  payload,
  onSuccess,
  onFailed,
});

export interface SetOrderPayload {
  order: Order | null;
  collaborators: Collaborator[] | null;
  address: ShippingAddress | null;
  isCurrentUserOwner: boolean;
  isCurrentUserEditor: boolean;
  isCurrentUserViewer: boolean;
}

export const setOrder = (payload: SetOrderPayload) => ({
  type: orderActions.SET_ORDER,
  payload,
});

export interface GetOrderRequest {
  orderId: string;
  isAsap?: boolean;
  isDuplicate?: boolean;
}

export const getOrder = (
  payload: GetOrderRequest,
  loading?: (isLoading: boolean) => void,
  onFailed?: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.GET_ORDER,
  payload,
  loading,
  onFailed,
});

export interface DeleteOrderRequest {
  orderId: string;
}

export const deleteOrder = (
  payload: DeleteOrderRequest,
  onSuccess: (orderId: string) => void,
  onFailed: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.DELETE_ORDER,
  payload,
  onSuccess,
  onFailed,
});

export const saveOrderExistingShippingAddress = (
  payload: OrderExistingShippingAddresRequest,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.SAVE_ORDER_EXISTING_SHIPPING_ADDRESS,
  payload,
  onSuccess,
  onFailed,
});

export const setNonExistingShippingAddress = (
  payload: ShippingAddress | null
) => ({
  type: orderActions.SET_ORDER_NON_EXISTING_SHIPPING_ADDRESS,
  payload,
});

export const clearNonExistingShippingAddress = () => ({
  type: orderActions.CLEAR_NON_EXISTING_SHIPPING_ADDRESS,
});

export const createNonExistingShippingAddress = (
  payload: CustomAddressRequestObject,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.CREATE_NON_EXISTING_SHIPPING_ADDRESS,
  payload,
  onSuccess,
  onFailed,
});

export const searchCollaborators = (
  payload: SearchCollaboratosRequest,
  loading: (isLoading: boolean) => void
) => ({
  type: orderActions.SEARCH_COLLABORATORS,
  payload,
  loading,
});

export const saveOrderCollaborators = (
  payload: OrderCollaboratorsRequest,
  onSuccess: () => void
) => ({
  type: orderActions.SAVE_ORDER_COLLABORATORS,
  payload,
  onSuccess,
});

export interface RemoveOrderCollaboratorRequest {
  userId: string;
  orderId: string;
}

export const removeOrderCollaborator = (
  payload: RemoveOrderCollaboratorRequest,
  onSuccess: () => void
) => ({
  type: orderActions.REMOVE_ORDER_COLLABORATOR,
  payload,
  onSuccess,
});

export const setSearchedCollaborators = (collaborators: User[] | null) => ({
  type: orderActions.SET_SEARCHED_COLLABORATORS,
  payload: collaborators,
});

export const selectCollaborator = (payload: Collaborator) => ({
  type: orderActions.SELECT_COLLABORATOR,
  payload,
});

export const deselectCollaborator = (collaboratorId: string) => ({
  type: orderActions.DESELECT_COLLABORATOR,
  payload: collaboratorId,
});

export const updateSelectedCollaborator = (collaborator: Collaborator) => ({
  type: orderActions.UPDATE_SELECTED_COLLABORATOR,
  payload: collaborator,
});

export const setSelectedCollaborators = (
  collaborators: Collaborator[] | null
) => ({
  type: orderActions.SET_SELECTED_COLLABORATORS,
  payload: collaborators,
});

export const clearSelectedCollaborators = () => ({
  type: orderActions.CLEAR_SELECTED_COLLABORATORS,
});

export const removeDealerCollaboratos = () => ({
  type: orderActions.REMOVE_DEALER_COLLABORATORS,
});

interface CollaboratorTypeChangeProps {
  type: number;
  collaboratorId: string;
}

export const changeCollaboratorType = (
  payload: CollaboratorTypeChangeProps
) => ({
  type: orderActions.CHANGE_COLLABORATOR_TYPE,
  payload,
});

export const addNewStyle = (style: Style) => ({
  type: orderActions.ADD_NEW_STYLE,
  payload: style,
});

export const getOrderStyles = (
  orderId: string,
  loading?: (isLoading: boolean) => void,
  onSuccess?: (data: Style[]) => void
) => ({
  type: orderActions.GET_ORDER_STYLES,
  payload: orderId,
  loading,
  onSuccess,
});

export const setOrderStyles = (orderId: Style[] | null) => ({
  type: orderActions.SET_ORDER_STYLES,
  payload: orderId,
});

export interface GetOrderStyleRequest {
  styleId: string;
  orderId: string;
}

export const getOrderStyle = (
  payload: GetOrderStyleRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: (style: Style) => void
) => ({
  type: orderActions.GET_ORDER_STYLE,
  payload,
  loading,
  onSuccess,
});

export const deleteOrderStyle = (
  payload: IOrderDeleteStyleRequest,
  onSuccess: (styleID: string) => void,
  onFailed: () => void
) => ({
  type: orderActions.DELETE_ORDER_STYLE,
  payload,
  onSuccess,
  onFailed,
});

export const uploadSingleFile = (
  file: File,
  onSuccess: (uploadedFile: FileUploadResponse) => void,
  onFailed: () => void
) => ({
  type: orderActions.UPLOAD_SINGLE_FILE,
  payload: file,
  onSuccess,
  onFailed,
});

export interface GetOrderLineItemsRequest {
  orderId: string;
  productLineId?: string;
  isSalesMaterial?: boolean;
}

export interface GetStyleLineItemsRequest {
  orderId: string;
  styleId?: string;
  searchTerm?: string;
}

export interface ImportLineItemsRequest {
  orderId: string;
  newOrderId: string;
  lineItemIds: string[];
  styleId: string;
  newStyleId: string;
}

export interface MoveLineItemsRequest {
  orderId: string;
  lineItemIds: string[];
  styleId: string;
  newStyleId: string;
}

export const getOrderLineItems = (
  payload: GetOrderLineItemsRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: (data: LineItem[]) => void
) => ({
  type: orderActions.GET_ORDER_LINE_ITEMS,
  payload,
  loading,
  onSuccess,
});

export const setOrderLineItems = (lineItems: LineItem[] | null) => ({
  type: orderActions.SET_ORDER_LINE_ITEMS,
  payload: lineItems,
});

export const filterDeletedOrderLineItem = (lineItemId: string) => ({
  type: orderActions.FILTER_DELETED_ORDER_LINE_ITEM,
  payload: lineItemId,
});

export interface GetOrderLineItemCLValuesRequest {
  orderId: string;
  productLineId?: string;
  isSalesMaterial?: boolean;
}

export interface UpdateStyleNoOfTopics {
  id: string;
  topicCount: number;
}

export interface UpdateLineItemNoOfTopics {
  lineItemId: string;
  topicCount: number;
}

export const getOrderLineItemCLValues = (
  payload: GetOrderLineItemCLValuesRequest
) => ({
  type: orderActions.GET_ORDER_LINE_ITEM_CL_VALUES,
  payload,
});

export interface UpdateOrderLineItemCLValuesPayload {
  lineItems: LineItem[];
  isOrderSalesMaterial: boolean;
}

export const updateOrderLineItemCLValues = (
  payload: UpdateOrderLineItemCLValuesPayload
) => ({
  type: orderActions.UPDATE_ORDER_LINE_ITEM_CL_VALUES,
  payload,
});

export const getOrderPriceReview = (
  payload: string,
  loading?: (isLoading: boolean) => void,
  onSuccess?: () => void
) => ({
  type: orderActions.GET_ORDER_PRICE_REVIEW,
  payload,
  loading,
  onSuccess,
});

export const setOrderPriceReview = (data: OrderStylePriceReview[] | null) => ({
  type: orderActions.SET_ORDER_PRICE_REVIEW,
  payload: data,
});

export interface GetOrderPriceReviewRequest {
  orderId: string;
  productLineId: string;
}

export const getOrderPriceReviewSM = (
  payload: GetOrderPriceReviewRequest,
  loading?: (isLoading: boolean) => void,
  onSuccess?: (data: OrderPriceReviewSM) => void
) => ({
  type: orderActions.GET_ORDER_PRICE_REVIEW_SM,
  payload,
  loading,
  onSuccess,
});

export const setOrderPriceReviewSM = (data: OrderPriceReviewSM | null) => ({
  type: orderActions.SET_ORDER_PRICE_REVIEW_SM,
  payload: data,
});

export const setStylePriority = (styleId: string, priority: number) => ({
  type: orderActions.SET_STYLE_PRIORITY,
  payload: {
    styleId,
    priority,
  },
});

export const setLineItemPriority = (lineItemId: string, priority: number) => ({
  type: orderActions.SET_LINEITEM_PRIORITY,
  payload: {
    lineItemId,
    priority,
  },
});

export const setOrderPriority = (priority: Priority) => ({
  type: orderActions.SET_ORDER_PRIORITY,
  payload: priority,
});

export const updateLineItemNoOfTopics = (
  payload: UpdateLineItemNoOfTopics
) => ({
  type: orderActions.UPDATE_LINE_ITEM_NO_OF_TOPICS,
  payload,
});

export const updateStyleNoOfTopics = (payload: UpdateStyleNoOfTopics) => ({
  type: orderActions.UPDATE_STYLE_NO_OF_TOPICS,
  payload,
});

export interface SubmitOrderRequest {
  orderId: string;
  isAsap: boolean;
}

export const submitOrder = (
  payload: SubmitOrderRequest,
  onSuccess: () => void,
  onFailed?: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderActions.SUBMIT_ORDER,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const getOrderStatus = (orderId: string) => ({
  type: orderActions.GET_ORDER_STATUS,
  payload: orderId,
});

export const setOrderStatus = (status: number) => ({
  type: orderActions.SET_ORDER_STATUS,
  payload: status,
});

export const getUnresolvedTopics = (
  payload: string,
  onSuccess?: () => void
) => ({
  type: orderActions.GET_UNRESOLVED_TOPICS,
  payload,
  onSuccess,
});

export const setNumberOfUnresolvedTopics = (count: number) => ({
  type: orderActions.SET_UNRESOLVED_TOPICS,
  payload: count,
});

export interface GetDealershipPreferencesRequest {
  dealershipId: string;
}

export const getDealershipPreferences = (
  payload: GetDealershipPreferencesRequest
) => ({
  type: orderActions.GET_DEALERSHIP_PREFERENCES,
  payload,
});

export const setDealershipPreferences = (dealershipPreferences: string[]) => ({
  type: orderActions.SET_DEALERSHIP_PREFERENCES,
  payload: dealershipPreferences,
});

export const getShipViaOptions = (
  onSuccess: (options: SelectOptionProps[]) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderActions.GET_SHIP_VIA_OPTIONS,
  onSuccess,
  loading,
});

export interface SaveShipViaOptionRequest {
  orderId: string;
  shipOptionId: string;
}

export const saveShipViaOption = (
  payload: SaveShipViaOptionRequest,
  onSuccess: () => void
) => ({
  type: orderActions.SAVE_SHIP_VIA_OPTION,
  payload,
  onSuccess,
});

export interface ChangeOrderGradeRequest {
  orderId?: string;
  gradingOption: GradingOption | null;
}
export const changeOrderGrade = (
  payload: ChangeOrderGradeRequest,
  onSuccess: () => void,
  onFailed: () => void
) => ({
  type: orderActions.CHANGE_ORDER_GRADE,
  payload,
  onSuccess,
  onFailed,
});
export interface UpdateLineItemCLPayload {
  lineItemId: string;
  clientNumber: number;
}

export const updateLineItemCL = (payload: UpdateLineItemCLPayload) => ({
  type: orderActions.UPDATE_LINE_ITEM_CL,
  payload,
});

export interface UpdateLineItemLPayload {
  lineItemId: string;
  number: number;
}

export const updateLineItemL = (payload: UpdateLineItemLPayload) => ({
  type: orderActions.UPDATE_LINE_ITEM_L,
  payload,
});

export interface GetLockInfoOnOrderReqest {
  orderId: string;
}

export const getLockInfoOnOrder = (
  payload: GetLockInfoOnOrderReqest,
  onSuccess?: (lockInfo: OrderLockInfo) => void,
  onFailed?: () => void
) => ({
  type: orderActions.GET_LOCK_INFO_ON_ORDER,
  payload,
  onSuccess,
  onFailed,
});

export const setLockInfoOnOrder = (orderLockInfo: OrderLockInfo | null) => ({
  type: orderActions.SET_LOCK_INFO_ON_ORDER,
  payload: orderLockInfo,
});

export interface LockOrderForEditRequest {
  orderId: string;
}

export const lockOrderForEdit = (
  payload: LockOrderForEditRequest,
  onSuccess: () => void,
  onFailed?: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderActions.LOCK_ORDER_FOR_EDIT,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export interface UnlockOrderForEditRequest {
  orderId: string;
  orderLockId: string;
}

export const unlockOrderForEdit = (
  payload: UnlockOrderForEditRequest,
  onSuccess?: () => void,
  onFailed?: (err: ServerErrorResponse) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderActions.UNLOCK_ORDER_FOR_EDIT,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const setCanEditOrder = (payload: boolean) => ({
  type: orderActions.SET_CAN_EDIT_ORDER,
  payload,
});

export interface KeepAliveOrderEditSessionRequest {
  orderId: string;
  orderLockId: string;
}

export const keepAliveOrderEditSession = (
  payload: KeepAliveOrderEditSessionRequest,
  onFailed: (err: ServerErrorResponse) => void
) => ({
  type: orderActions.KEEP_ALIVE_ORDER_EDIT_SESSION,
  payload,
  onFailed,
});

export interface UpdateOrderStatusRequest {
  orderId: string;
  orderStatusId: number;
}

export const updateOrderStatus = (
  payload: UpdateOrderStatusRequest,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderActions.UPDATE_ORDER_STATUS,
  payload,
  onSuccess,
  loading,
});

export const replaceOrderStatus = (status: BaseField) => ({
  type: orderActions.REPLACE_ORDER_STATUS,
  payload: status,
});

export const getStatusEditLog = (
  payload: string,
  loading: (isLoading: boolean) => void
) => ({
  type: orderActions.GET_STATUS_EDIT_LOG,
  payload,
  loading,
});

export const setStatusEditLog = (statusLog: OrderStatusEditLog[]) => ({
  type: orderActions.SET_STATUS_EDIT_LOG,
  payload: statusLog,
});

export interface StyleCancelRequestRequest {
  orderId: string;
  styleId: string;
}

export const styleCancelRequest = (
  payload: StyleCancelRequestRequest,
  onSuccess: () => void
) => ({
  type: orderActions.STYLE_CANCEL_REQUEST,
  payload,
  onSuccess,
});

export const setStyleCancelRequested = (styleId: string) => ({
  type: orderActions.SET_STYLE_CANCEL_REQUEST,
  payload: styleId,
});

export const setOrderAckStatus = (ackStatus: ACKStatusEnum) => ({
  type: orderActions.UPDATE_ORDER_ACK_STATUS,
  payload: ackStatus,
});

export const setOrderAckId = (ackId: string) => ({
  type: orderActions.UPDATE_ORDER_ACK_ID,
  payload: ackId,
});

export const setCurrentUserCollaboratorAccess = (
  payload: Pick<
    OrderInitialStore,
    'isCurrentUserEditor' | 'isCurrentUserOwner' | 'isCurrentUserViewer'
  >
) => ({
  type: orderActions.SET_CURRENT_USER_COLLABORATOR_ACCESS,
  payload,
});

export const setOrderIsValid = (isValid: boolean) => ({
  type: orderActions.SET_ORDER_IS_VALID,
  payload: isValid,
});

export const setOrderIsLocked = (isLocked: boolean) => ({
  type: orderActions.SET_ORDER_IS_LOCKED,
  payload: isLocked,
});

export const setSpecialOrderApproval = (
  specialOrderApproval: SpecialOrderApproval
) => ({
  type: orderActions.SET_SPECIAL_ORDER_APPROVAL,
  payload: specialOrderApproval,
});

export const updateSpecialOrderApprovalStatus = (
  specialOrderApprovalStatus: SpecialOrderApprovalStatusEnum
) => ({
  type: orderActions.UPDATE_SPECIAL_ORDER_APPROVAL_STATUS,
  payload: specialOrderApprovalStatus,
});

export const setAdjustmentsData = (payload: OrderStylesAdjustments) => ({
  type: orderActions.SET_ADJUSTMENT_DATA,
  payload,
});

export const updateScheduleType = (payload: ScheduleType) => ({
  type: orderActions.UPDATE_SCHEDULE_TYPE,
  payload,
});

export const getDealerships = (
  loading?: (isLoading: boolean) => void,
  onSuccess?: (data: Dealership[]) => void
) => ({
  type: orderActions.GET_DEALERSHIPS,
  loading,
  onSuccess,
});

export const setDealerships = (dealershipOptions: Dealership[]) => ({
  type: orderActions.SET_DEALERSHIPS,
  payload: dealershipOptions,
});

export const getDealershipUsers = (
  payload: string | null,
  loading: (isLoading: boolean) => void
) => ({
  type: orderActions.GET_DEALERSHIP_USERS,
  payload,
  loading,
});

export const setDealershipUsers = (
  dealershipUsersOptions: SelectOptionProps[]
) => ({
  type: orderActions.SET_DEALERSHIP_USERS,
  payload: dealershipUsersOptions,
});

export const clearDealershipUsers = () => ({
  type: orderActions.CLEAR_DEALERSHIP_USERS,
});

export const setShouldNavigateToTopic = (payload: boolean) => ({
  type: orderActions.SET_SHOULD_NAVIGATE_TO_TOPIC,
  payload,
});

export const setAssigneesOnOrder = (payload: User[]) => ({
  type: orderActions.SET_ASSIGNEES_ON_ORDER,
  payload,
});

export const getUpchargeDifferences = (orderId: string) => ({
  type: orderActions.GET_UPCHARGE_DIFFERENCES,
  payload: orderId,
});

export const setUpchargeDifferences = (
  upchargeDifferences: UpchargeDifference[]
) => ({
  type: orderActions.SET_UPCHARGE_DIFFERENCES,
  payload: upchargeDifferences,
});

export const setUpchargeDifferencesModalOpened = ({
  opened,
  onSubmit,
}: {
  opened: boolean;
  onSubmit?: () => void;
}) => ({
  type: orderActions.SET_UPCHARGE_DIFFERENCES_MODAL_OPENED,
  payload: { opened, onSubmit },
});

export const overrideUpcharges = (
  orderId: string,
  onSuccess: () => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderActions.OVERRIDE_UPCHARGES,
  payload: orderId,
  onSuccess,
  loading,
});

export const setUpchargesOverriden = (overriden: boolean) => ({
  type: orderActions.UPCHARGES_OVERRIDEN,
  payload: overriden,
});

export interface ExportPDFRequest {
  orderId: string;
  noPricing: boolean;
}

export const exportAsPDF = (
  payload: ExportPDFRequest,
  onSuccess: (pdfSnapshot: IFileData) => void,
  onFailed: (error: ServerErrorResponse) => void,
  loading: (isLoading: boolean) => void
) => ({
  type: orderActions.EXPORT_AS_PDF,
  payload,
  onSuccess,
  onFailed,
  loading,
});

export const getShippingAddress = (
  orderId: string,
  onSuccess?: () => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: orderActions.GET_SHIPPING_ADDRESS,
  payload: orderId,
  onSuccess,
  loading,
});

export const setShippingAddress = (address: ShippingAddress | null) => ({
  type: orderActions.SET_SHIPPING_ADDRESS,
  payload: address,
});

export const updateLineItemNoOfUnreadTopics = (
  payload: UpdateLineItemNoOfTopics
) => ({
  type: orderActions.UPDATE_LINE_ITEM_NO_OF_UNREAD_TOPICS,
  payload,
});

export const updateStyleNoOfUnreadTopics = (
  payload: UpdateStyleNoOfTopics
) => ({
  type: orderActions.UPDATE_STYLE_NO_OF_UNREAD_TOPICS,
  payload,
});
