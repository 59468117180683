import { BaseField } from 'shared/interface/BaseField';
import { Dealership } from 'shared/interface/Dealership';
import { SalesRepresentative } from 'shared/interface/SalesRepresentative';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { TableColumnIdEnums } from 'shared/enum/tableEnums';

import { PriceVariablesForm } from '../components/DealershipPricingForm';

export enum dealershipActions {
  CLEAR_DEALERSHIP = 'CLEAR_DEALERSHIP',

  GET_DEALERSHIP = 'GET_DEALERSHIP',
  SET_CURRENT_DEALERSHIP = 'SET_CURRENT_DEALERSHIP',

  GET_DEALERSHIP_CUSTOM_ADDRESSES = 'GET_DEALERSHIP_CUSTOM_ADDRESSES',
  SET_DEALERSHIP_CUSTOM_ADDRESSES = 'SET_DEALERSHIP_CUSTOM_ADDRESSES',
  SORT_DEALERSHIP_CUSTOM_ADDRESSES = 'SORT_DEALERSHIP_CUSTOM_ADDRESSES',

  GET_DEALERSHIP_SALES_REPRESENTATIVE = 'GET_DEALERSHIP_SALES_REPRESENTATIVE',
  SET_DEALERSHIP_SALES_REPRESENTATIVE = 'SET_DEALERSHIP_SALES_REPRESENTATIVE',

  GET_DEALERSHIP_GLOBAL_PRICE_VARIABLES = 'GET_DEALERSHIP_GLOBAL_PRICE_VARIABLES',
  SET_DEALERSHIP_GLOBAL_PRICE_VARIABLES = 'SET_DEALERSHIP_GLOBAL_PRICE_VARIABLES',

  UPDATE_DEALERSHIP_CUSTOM_PRICES = 'UPDATE_DEALERSHIP_CUSTOM_PRICES',
  SET_DEALERSHIP_CUSTOM_PRICES = 'SET_DEALERSHIP_CUSTOM_PRICES',

  ADD_DEALERSHIP_CUSTOM_ADDRESS = 'ADD_DEALERSHIP_CUSTOM_ADDRESS',
  UPDATE_DEALERSHIP_CUSTOM_ADDRESS = 'UPDATE_DEALERSHIP_CUSTOM_ADDRESS',

  SET_AVAILABLE_DEALERSHIPS = 'SET_AVAILABLE_DEALERSHIPS',
  GET_AVAILABLE_DEALERSHIPS = 'GET_AVAILABLE_DEALERSHIPS',

  SET_FILTERED_DEALERSHIPS = 'SET_FILTERED_DEALERSHIPS',
  GET_FILTERED_DEALERSHIPS = 'GET_FILTERED_DEALERSHIPS',
}

export const clearDealership = () => ({
  type: dealershipActions.CLEAR_DEALERSHIP,
});

export interface DealershipCustomPricesRequest extends PriceVariablesForm {
  dealershipId: string;
}

export const updateDealershipCustomPriceVariables = (
  payload: DealershipCustomPricesRequest,
  onSuccess: () => void,
  onFailed: (err: ServerErrorResponse) => void
) => ({
  type: dealershipActions.UPDATE_DEALERSHIP_CUSTOM_PRICES,
  payload,
  onSuccess,
  onFailed,
});

export const setDealershipCustomPriceVariables = (
  priceVariables: PriceVariablesForm
) => ({
  type: dealershipActions.SET_DEALERSHIP_CUSTOM_PRICES,
  payload: priceVariables,
});

export interface GetDealershipRequest {
  dealershipId: string;
  saveToStore?: boolean;
}

export const getDealership = (
  payload: GetDealershipRequest,
  onSuccess?: (dealership: Dealership) => void,
  loading?: (isLoading: boolean) => void
) => ({
  type: dealershipActions.GET_DEALERSHIP,
  payload,
  onSuccess,
  loading,
});

export const setDealership = (payload: Dealership | null) => ({
  type: dealershipActions.SET_CURRENT_DEALERSHIP,
  payload,
});

export const getFilteredDealerships = () => ({
  type: dealershipActions.GET_FILTERED_DEALERSHIPS,
});

export interface GetDealershipSalesRepRequest {
  dealershipId: string;
}

export const getDealershipSalesRep = (
  payload: GetDealershipSalesRepRequest,
  onSuccess?: (salesRep: SalesRepresentative) => void
) => ({
  type: dealershipActions.GET_DEALERSHIP_SALES_REPRESENTATIVE,
  payload,
  onSuccess,
});

export const setDealershipSalesRep = (payload: SalesRepresentative | null) => ({
  type: dealershipActions.SET_DEALERSHIP_SALES_REPRESENTATIVE,
  payload,
});

export const getAvailableDealerships = () => ({
  type: dealershipActions.GET_AVAILABLE_DEALERSHIPS,
});

export const getDealershipGlobalPriceVariables = (dealershipId: string) => ({
  type: dealershipActions.GET_DEALERSHIP_GLOBAL_PRICE_VARIABLES,
  payload: dealershipId,
});

export const setAvailableDealerships = (
  availableDealerships: BaseField[] | null
) => ({
  type: dealershipActions.SET_AVAILABLE_DEALERSHIPS,
  payload: availableDealerships,
});

export interface SortDealershipCustomAddresses {
  sortColumn: TableColumnIdEnums;
  asc: boolean;
}

export const sortDealershipCustomAddresses = (
  payload: SortDealershipCustomAddresses
) => ({
  type: dealershipActions.SORT_DEALERSHIP_CUSTOM_ADDRESSES,
  payload,
});

export const getDealershipCustomAddresses = (
  dealershipId: string,
  onSuccess: () => void
) => ({
  type: dealershipActions.GET_DEALERSHIP_CUSTOM_ADDRESSES,
  payload: dealershipId,
  onSuccess,
});
