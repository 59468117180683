import { AnyAction } from 'redux';

import { ACKStatusEnum } from 'order/wizard/orderAcknowledgement/enums/ACKStatusEnum';
import { Collaborator } from 'order/interfaces/Collaborator';
import { Job } from 'order/interfaces/Job';
import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { OrderStylesAdjustments } from 'order/wizard/orderStyles/interface/Adjustments';
import { Priority } from 'order/enums/priorityEnum';
import { ScheduleType } from 'order/enums/scheduleType';
import { SpecialOrderApproval } from 'order/wizard/orderAcknowledgement/interface/SpecialOrderApproval';
import { SpecialOrderApprovalStatusEnum } from 'order/wizard/orderAcknowledgement/enums/SpecialOrderApprovalStatusEnum';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { UpchargeDifference } from 'order/interfaces/UpchargeDifference';
import { collaboratorAccessEnums } from 'order/enums/collaboratorAccessEnums';
import { orderAttachmentsActions } from 'order/wizard/orderAttachments/store/orderAttachmentsActions';

import {
  OrderPriceReview,
  OrderPriceReviewSM,
} from 'order/wizard/orderStyles/interface/OrderPriceReview';

import {
  Order,
  OrderLockInfo,
  OrderStatusEditLog,
} from 'order/interfaces/Order';

import { BaseField } from 'shared/interface/BaseField';
import { IAttachment } from 'shared/interface/IAttachment';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { User } from 'shared/interface/User';
import { UserRolesEnum } from 'shared/enum/userRolesEnum';
import { Dealership } from 'shared/interface/Dealership';

import {
  orderActions,
  SetOrderPayload,
  UpdateLineItemNoOfTopics,
  UpdateOrderLineItemCLValuesPayload,
  UpdateStyleNoOfTopics,
} from './orderActions';

export interface OrderInitialStore {
  jobs: PaginatedItems<Job> | null;
  order: Order | null;
  styles: Style[] | null;
  lineItems: LineItem[] | null;
  searchedCollaborators: User[] | null;
  selectedCollaborators: Collaborator[] | null;
  selectedShippingAddress: ShippingAddress | null;
  nonExistingAddress: ShippingAddress | null;
  attachments: IAttachment[] | null;
  attachmentsForCheckedLineItems: IAttachment[] | null;
  priceReview: OrderPriceReview | null;
  priceReviewSM: OrderPriceReviewSM | null;
  isCurrentUserOwner: boolean;
  isCurrentUserEditor: boolean;
  isCurrentUserViewer: boolean;
  numberOfUnresolvedTopics: number;
  dealershipPreferences: string[] | null;
  lockInfo: OrderLockInfo | null;
  canEdit: boolean;
  statusEditLog: OrderStatusEditLog[] | null;
  adjustment: OrderStylesAdjustments | null;
  dealerships: Dealership[] | null;
  dealershipUsers: SelectOptionProps[] | null;
  shouldNavigateToTopic: boolean | null;
  upchargeDifferences: UpchargeDifference[] | null;
  upchargeDifferencesModalOpened: { opened: boolean; onSubmit?: () => void };
  upchargesOverriden: boolean;
  collaborationAttachments: IAttachment[] | null;
}

const initialState: OrderInitialStore = {
  jobs: null,
  order: null,
  searchedCollaborators: null,
  selectedCollaborators: null,
  selectedShippingAddress: null,
  nonExistingAddress: null,
  styles: null,
  attachments: null,
  attachmentsForCheckedLineItems: null,
  lineItems: null,
  priceReview: null,
  priceReviewSM: null,
  isCurrentUserOwner: false,
  isCurrentUserEditor: false,
  isCurrentUserViewer: false,
  numberOfUnresolvedTopics: 0,
  dealershipPreferences: null,
  lockInfo: null,
  canEdit: false,
  statusEditLog: null,
  adjustment: null,
  dealerships: null,
  dealershipUsers: null,
  shouldNavigateToTopic: null,
  upchargeDifferences: null,
  upchargeDifferencesModalOpened: { opened: false },
  upchargesOverriden: false,
  collaborationAttachments: null,
};

export const orderReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case orderActions.CLEAR_ORDER:
      return { ...initialState };
    case orderActions.ADD_NEW_JOB: {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          items: [...(state.jobs?.items ?? []), action.payload as Job],
        } as PaginatedItems<Job>,
      };
    }
    case orderActions.SET_JOBS:
      return {
        ...state,
        jobs: {
          ...action.payload.jobs,
          items:
            state.jobs && action.payload.append
              ? [...(state.jobs?.items ?? []), ...action.payload.jobs.items]
              : [...action.payload.jobs.items],
        } as PaginatedItems<Job>,
      };
    case orderActions.SET_ORDER: {
      const {
        address,
        collaborators,
        isCurrentUserEditor,
        isCurrentUserOwner,
        isCurrentUserViewer,
        order,
      } = action.payload as SetOrderPayload;

      return {
        ...state,
        order,
        selectedCollaborators: collaborators,
        selectedShippingAddress: address,
        isCurrentUserOwner,
        isCurrentUserEditor,
        isCurrentUserViewer,
      };
    }
    case orderActions.SET_CURRENT_USER_COLLABORATOR_ACCESS:
      return {
        ...state,
        isCurrentUserOwner: action.payload.isCurrentUserOwner as boolean,
        isCurrentUserEditor: action.payload.isCurrentUserEditor as boolean,
        isCurrentUserViewer: action.payload.isCurrentUserViewer as boolean,
      };
    case orderActions.SET_CAN_EDIT_ORDER:
      return {
        ...state,
        canEdit: action.payload as boolean,
      };
    case orderActions.SET_SEARCHED_COLLABORATORS:
      return {
        ...state,
        searchedCollaborators: action.payload as User[],
      };
    case orderActions.SELECT_COLLABORATOR: {
      const selectedCollabs = state.selectedCollaborators;

      const updatedCollabs = selectedCollabs
        ? [...selectedCollabs, action.payload as Collaborator]
        : [action.payload as Collaborator];

      return {
        ...state,
        selectedCollaborators: updatedCollabs,
      };
    }
    case orderActions.DESELECT_COLLABORATOR: {
      const filteredCollaborators = state.selectedCollaborators!.filter(
        (collaborator) => collaborator.id !== action.payload
      );

      return {
        ...state,
        selectedCollaborators: filteredCollaborators.length
          ? filteredCollaborators
          : null,
      };
    }
    case orderActions.REMOVE_DEALER_COLLABORATORS: {
      const nonDealerCollaborators =
        state.selectedCollaborators?.filter((selectedCollaborator) =>
          selectedCollaborator.roles.some(
            (selectedCollabortorRole) =>
              selectedCollabortorRole ===
              UserRolesEnum.CustomerSalesRepresentative
          )
        ) ?? null;

      return {
        ...state,
        selectedCollaborators: nonDealerCollaborators,
      };
    }
    case orderActions.UPDATE_SELECTED_COLLABORATOR: {
      const updatedCollab = action.payload as Collaborator;

      return {
        ...state,
        selectedCollaborators: state
          .selectedCollaborators!.filter(
            (collab) =>
              !(
                collab.id === updatedCollab.id &&
                collab.collaboratorType === collaboratorAccessEnums.EDITOR
              )
          )
          .map((collab) =>
            updatedCollab.collaboratorType === collab.collaboratorType
              ? updatedCollab
              : collab
          ),
      };
    }

    case orderActions.CHANGE_COLLABORATOR_TYPE: {
      const selectedCollabs = state.selectedCollaborators!.map(
        (collaborator) => {
          if (collaborator.id === action.payload.collaboratorId) {
            return {
              ...collaborator,
              collaboratorType: action.payload.type,
            };
          }

          return collaborator;
        }
      );

      return {
        ...state,
        selectedCollaborators: selectedCollabs as Collaborator[],
      };
    }
    case orderActions.SET_ORDER_NON_EXISTING_SHIPPING_ADDRESS:
      return {
        ...state,
        nonExistingAddress: action.payload as ShippingAddress,
      };

    case orderActions.CLEAR_NON_EXISTING_SHIPPING_ADDRESS:
      return {
        ...state,
        nonExistingAddress: null,
      };
    case orderActions.SET_SELECTED_COLLABORATORS:
      return {
        ...state,
        selectedCollaborators: action.payload as Collaborator[],
      };
    case orderActions.CLEAR_SELECTED_COLLABORATORS:
      return {
        ...state,
        selectedCollaborators: null,
      };
    case orderActions.ADD_NEW_STYLE:
      return {
        ...state,
        styles: state.styles
          ? [...state.styles, action.payload as Style]
          : [action.payload as Style],
      };
    case orderActions.SET_ORDER_STYLES:
      return {
        ...state,
        styles: action.payload as Style[],
      };
    case orderActions.SET_ORDER_LINE_ITEMS:
      return {
        ...state,
        lineItems: action.payload as LineItem[],
      };
    case orderActions.FILTER_DELETED_ORDER_LINE_ITEM:
      return {
        ...state,
        lineItems: state.lineItems
          ? state.lineItems
              ?.filter((lineItem) => lineItem.lineItemId !== action.payload)
              .map((lineItem, index) => ({
                ...lineItem,
                number: index + 1,
              }))
          : null,
      };
    case orderAttachmentsActions.SET_ORDER_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload as IAttachment[],
      };
    case orderAttachmentsActions.SET_ATTACHMENTS_FOR_CHECKED_LINE_ITEMS:
      return {
        ...state,
        attachmentsForCheckedLineItems: action.payload as IAttachment[],
      };
    case orderActions.SET_ORDER_PRICE_REVIEW:
      return {
        ...state,
        priceReview: action.payload as OrderPriceReview,
      };
    case orderActions.SET_ORDER_PRICE_REVIEW_SM:
      return {
        ...state,
        priceReviewSM: action.payload as OrderPriceReviewSM,
      };
    case orderActions.SET_STYLE_PRIORITY: {
      const index = state.styles?.findIndex(
        (style) => style.id === action.payload.styleId
      );
      const newArray = [...state.styles!];
      newArray[index!].priority = action.payload.priority;

      return {
        ...state,
        styles: newArray,
      };
    }
    case orderActions.SET_LINEITEM_PRIORITY: {
      const index = state.lineItems?.findIndex(
        (lineItem) => lineItem.lineItemId === action.payload.lineItemId
      );
      const newArray = [...state.lineItems!];
      newArray[index!].priority = action.payload.priority;

      return {
        ...state,
        lineItems: newArray,
      };
    }
    case orderActions.SET_ORDER_PRIORITY:
      return {
        ...state,
        order: {
          ...state.order!,
          priority: action.payload as Priority,
        } as Order,
      };
    case orderActions.UPDATE_LINE_ITEM_NO_OF_TOPICS: {
      const { lineItemId, topicCount } =
        action.payload as UpdateLineItemNoOfTopics;

      return {
        ...state,
        lineItems: (state.lineItems ?? []).map((lineItem) => {
          if (lineItem.lineItemId === lineItemId) {
            return {
              ...lineItem,
              numberOfTopics: topicCount,
            };
          }

          return lineItem;
        }),
      };
    }
    case orderActions.UPDATE_LINE_ITEM_NO_OF_UNREAD_TOPICS: {
      const { lineItemId, topicCount } =
        action.payload as UpdateLineItemNoOfTopics;

      return {
        ...state,
        lineItems: (state.lineItems ?? []).map((lineItem) => {
          if (lineItem.lineItemId === lineItemId) {
            return {
              ...lineItem,
              numberOfUnreadTopics: topicCount,
            };
          }

          return lineItem;
        }),
      };
    }
    case orderActions.UPDATE_STYLE_NO_OF_TOPICS: {
      const { id, topicCount } = action.payload as UpdateStyleNoOfTopics;
      return {
        ...state,
        styles: (state.styles ?? []).map((style) => {
          if (style.id === id) {
            return {
              ...style,
              topicCount,
            };
          }

          return style;
        }),
      };
    }
    case orderActions.UPDATE_STYLE_NO_OF_UNREAD_TOPICS: {
      const { id, topicCount } = action.payload as UpdateStyleNoOfTopics;
      return {
        ...state,
        styles: (state.styles ?? []).map((style) => {
          if (style.id === id) {
            return {
              ...style,
              unreadTopicCount: topicCount,
            };
          }

          return style;
        }),
      };
    }
    case orderActions.SET_ORDER_STATUS:
      return {
        ...state,
        order: {
          ...state.order!,
          status: {
            id: action.payload,
            name: OrderStatusEnums[action.payload],
          } as BaseField,
        } as Order,
      };
    case orderActions.SET_UNRESOLVED_TOPICS:
      return {
        ...state,
        numberOfUnresolvedTopics: action.payload,
      };
    case orderActions.SET_DEALERSHIP_PREFERENCES:
      return {
        ...state,
        dealershipPreferences: action.payload as string[],
      };
    case orderActions.UPDATE_LINE_ITEM_CL:
      if (state.lineItems) {
        return {
          ...state,
          lineItems: state.lineItems.map((lineItem) => {
            if (lineItem.lineItemId === action.payload.lineItemId) {
              return {
                ...lineItem,
                clientLineItemNumber: action.payload.clientNumber,
              } as LineItem;
            }

            return lineItem as LineItem;
          }),
        };
      }

      return state;

    case orderActions.UPDATE_LINE_ITEM_L:
      if (state.lineItems) {
        return {
          ...state,
          lineItems: state.lineItems.map((lineItem) => {
            if (lineItem.lineItemId === action.payload.lineItemId) {
              return {
                ...lineItem,
                number: action.payload.number,
              } as LineItem;
            }

            return lineItem as LineItem;
          }),
        };
      }

      return state;

    case orderActions.SET_LOCK_INFO_ON_ORDER:
      return {
        ...state,
        lockInfo: action.payload as OrderLockInfo,
      };

    case orderActions.REPLACE_ORDER_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          status: action.payload as BaseField,
        } as Order,
      };
    case orderActions.SET_STATUS_EDIT_LOG:
      return {
        ...state,
        statusEditLog: action.payload as OrderStatusEditLog[],
      };
    case orderActions.SET_STYLE_CANCEL_REQUEST:
      return {
        ...state,
        styles: (state.styles ?? [])?.map((style) =>
          style.id === action.payload
            ? { ...style, cancellationRequested: !style.cancellationRequested }
            : style
        ),
      };
    case orderActions.UPDATE_ORDER_ACK_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          acknowledgementStatus: action.payload as ACKStatusEnum,
        } as Order,
      };
    case orderActions.UPDATE_ORDER_ACK_ID:
      return {
        ...state,
        order: {
          ...state.order,
          acknowledgementId: action.payload as string,
        } as Order,
      };
    case orderActions.SET_ORDER_IS_VALID:
      return {
        ...state,
        order: {
          ...state.order,
          isValid: action.payload,
        } as Order,
      };
    case orderActions.SET_ORDER_IS_LOCKED:
      return {
        ...state,
        order: {
          ...state.order,
          isLocked: action.payload,
        } as Order,
      };
    case orderActions.SET_SPECIAL_ORDER_APPROVAL:
      return {
        ...state,
        order: {
          ...state.order,
          specialOrderApproval: action.payload as SpecialOrderApproval,
        } as Order,
      };
    case orderActions.UPDATE_SPECIAL_ORDER_APPROVAL_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          specialOrderApproval: {
            ...state.order?.specialOrderApproval,
            status: action.payload as SpecialOrderApprovalStatusEnum,
          } as SpecialOrderApproval,
        } as Order,
      };
    case orderActions.SET_ADJUSTMENT_DATA:
      return {
        ...state,
        order: {
          ...state.order,
          adjustment: action.payload,
        } as Order,
      };
    case orderActions.UPDATE_ORDER_LINE_ITEM_CL_VALUES: {
      const { isOrderSalesMaterial, lineItems } =
        action.payload as UpdateOrderLineItemCLValuesPayload;

      if (isOrderSalesMaterial) {
        const deepClonedPriceReviewSM = JSON.parse(
          JSON.stringify(state.priceReviewSM ?? null)
        ) as OrderPriceReviewSM;

        deepClonedPriceReviewSM.lineItems =
          deepClonedPriceReviewSM.lineItems.map((lineItem) => ({
            ...lineItem,
            clientLineItemNumber:
              lineItems.find((li) => lineItem.lineItemId === li.lineItemId)
                ?.clientLineItemNumber ?? null,
          }));

        return {
          ...state,
          priceReviewSM: deepClonedPriceReviewSM,
        };
      }

      const deepClonedPriceReview = JSON.parse(
        JSON.stringify(state.priceReview)
      ) as OrderPriceReview;

      deepClonedPriceReview.orderStylePriceReview =
        deepClonedPriceReview.orderStylePriceReview.map((stylePriceReview) => ({
          ...stylePriceReview,
          lineItemPriceReview: stylePriceReview.lineItemPriceReview.map(
            (lineItem) => ({
              ...lineItem,
              clientLineItemNumber:
                lineItems.find((li) => lineItem.lineItemId === li.lineItemId)
                  ?.clientLineItemNumber ?? null,
            })
          ),
        }));

      return {
        ...state,
        priceReview: deepClonedPriceReview,
      };
    }
    case orderActions.UPDATE_SCHEDULE_TYPE:
      return {
        ...state,
        order: {
          ...state.order,
          scheduleType: action.payload as ScheduleType,
        } as Order,
      };
    case orderActions.SET_DEALERSHIPS:
      return {
        ...state,
        dealerships: action.payload as Dealership[],
      };
    case orderActions.SET_DEALERSHIP_USERS:
      return {
        ...state,
        dealershipUsers: action.payload as SelectOptionProps[],
      };
    case orderActions.CLEAR_DEALERSHIP_USERS:
      return {
        ...state,
        dealershipUsers: null,
      };
    case orderActions.SET_SHOULD_NAVIGATE_TO_TOPIC:
      return {
        ...state,
        shouldNavigateToTopic: action.payload as boolean,
      };
    case orderActions.SET_ASSIGNEES_ON_ORDER: {
      const assigneeResponses = action.payload as User[];

      return {
        ...state,
        order: {
          ...state.order,
          assigneeResponses,
          assigneeIds: assigneeResponses.map((assignee) => assignee.id),
        } as Order,
      };
    }
    case orderActions.SET_UPCHARGE_DIFFERENCES: {
      const upchargeDifferences = action.payload as UpchargeDifference[] | null;
      return {
        ...state,
        upchargeDifferences,
        upchargeDifferencesModalOpened: {
          opened: (upchargeDifferences ?? []).length > 0,
        },
      };
    }
    case orderActions.SET_UPCHARGE_DIFFERENCES_MODAL_OPENED:
      return {
        ...state,
        upchargeDifferencesModalOpened: action.payload as {
          opened: boolean;
          onSubmit?: () => void;
        },
      };
    case orderActions.UPCHARGES_OVERRIDEN:
      return {
        ...state,
        upchargesOverriden: action.payload as boolean,
      };
    case orderActions.SET_SHIPPING_ADDRESS:
      return {
        ...state,
        selectedShippingAddress: action.payload as ShippingAddress | null,
      };
    case orderAttachmentsActions.SET_COLLABORATION_ATTACHMENTS:
      return {
        ...state,
        collaborationAttachments: action.payload as IAttachment[] | null,
      };
    default:
      return state;
  }
};
