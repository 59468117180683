import { AnyAction } from 'redux';

import { BaseField } from 'shared/interface/BaseField';
import { Dealership } from 'shared/interface/Dealership';
import { PriceVariablesGlobal } from 'shared/interface/PriceVariables';
import { SalesRepresentative } from 'shared/interface/SalesRepresentative';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { TableColumnIdEnums } from 'shared/enum/tableEnums';
import UtilService from 'shared/services/util.service';

import { dealershipActions } from './dealershipActions';

export interface DealershipReducerInterface {
  availableDealerships: Dealership[];
  customAddresses: ShippingAddress[] | null;
  dealership: Dealership | null;
  priceVariablesGlobal: PriceVariablesGlobal | null;
  salesRepresentative: SalesRepresentative | null;
  filteredDealerships: BaseField[];
}

const initialState: DealershipReducerInterface = {
  availableDealerships: [],
  customAddresses: null,
  dealership: null,
  priceVariablesGlobal: null,
  salesRepresentative: null,
  filteredDealerships: [],
};

export const dealershipReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case dealershipActions.CLEAR_DEALERSHIP: {
      return {
        ...state,
        dealership: null,
      };
    }
    case dealershipActions.SET_AVAILABLE_DEALERSHIPS:
      return {
        ...state,
        availableDealerships: action.payload as Dealership[],
      };
    case dealershipActions.SET_CURRENT_DEALERSHIP:
      return { ...state, dealership: action.payload as Dealership };
    case dealershipActions.SET_DEALERSHIP_CUSTOM_ADDRESSES:
      return {
        ...state,
        customAddresses: action.payload as ShippingAddress[],
      };
    case dealershipActions.SORT_DEALERSHIP_CUSTOM_ADDRESSES:
      switch (action.payload.sortColumn) {
        case TableColumnIdEnums.CITY:
          return {
            ...state,
            customAddresses: state.customAddresses && [
              ...state.customAddresses.sort((a, b) =>
                UtilService.sortBy(a.city, b.city, action.payload.asc)
              ),
            ],
          };
        case TableColumnIdEnums.ZIPCODE:
          return {
            ...state,
            customAddresses: state.customAddresses && [
              ...state.customAddresses!.sort((a, b) =>
                UtilService.sortBy(a.zipCode, b.zipCode, action.payload.asc)
              ),
            ],
          };
        default:
          return state;
      }
    case dealershipActions.SET_DEALERSHIP_SALES_REPRESENTATIVE:
      return {
        ...state,
        salesRepresentative: action.payload as SalesRepresentative,
      };
    case dealershipActions.SET_DEALERSHIP_GLOBAL_PRICE_VARIABLES:
      return {
        ...state,
        priceVariablesGlobal: action.payload as PriceVariablesGlobal,
      };
    case dealershipActions.SET_DEALERSHIP_CUSTOM_PRICES:
      return {
        ...state,
        dealership: {
          ...state.dealership,
          salesTax: action.payload.salesTax,
          grossProfit: action.payload.grossProfit,
        } as Dealership,
      };
    case dealershipActions.SET_FILTERED_DEALERSHIPS:
      return {
        ...state,
        filteredDealerships: action.payload as BaseField[],
      };
    default:
      return state;
  }
};
