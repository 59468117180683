import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import OrderStyle from 'order/components/OrderStyle/OrderStyle';
import { CheckedStyle } from 'order/wizard/orderASAP/interface/CheckedItems';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { copySelectedItems } from 'order/wizard/orderASAP/store/OrderAsapActions';
import { getOrderStyles } from 'order/store/orderActions';

import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import { ButtonPrimary } from 'shared/components/Button';
import { P } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import SearchInput from '../../../../shared/components/SearchInput';
import { ImportContainer } from '../ImportOrders/ImportSearchOrders';
import { ImportStylesModalHeaderEnums } from '../../orderStyles/enums/ImportStylesModalEnum';
import { ImportStylesModalStepsEnums } from '../enums/ImportStylesModalStepsEnums';
import { Style } from '../../orderStyles/interface/Style';
import { setStylesModalOpened } from '../../orderStyles/productLines/store/orderStylesActions';
import { useInputSearch } from '../../../../shared/hooks/useInputSearch';

import {
  selectStyleForImport,
  setImportStylesModalMeta,
  setStylesForImport,
} from '../store/ImportActions';

const ImportStyleSelectContainer = styled.div`
  margin: 2px 0 0 2px;
`;

const ImportStyleSelect = () => {
  const { orderId } = useParams<OrderPageParams>();

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [query, onChange] = useInputSearch();

  const dispatch = useAppDispatch();

  const [stylesLoading, setStylesLoading] = useState(true);

  const [copyingStylesLoading, setCopyingStylesLoading] = useState(false);

  const importOrderId = useSelector(
    (state: RootState) => state.importStylesReducer.orderId
  );

  const styles = useSelector(
    (state: RootState) => state.importStylesReducer.styles
  );

  const { selectedStyle: selectedStyleToImportFrom } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const filteredStyles = useMemo<Style[] | null>(
    () =>
      styles?.filter(
        (style) =>
          style.name.toLowerCase().includes(query) &&
          selectedStyleToImportFrom?.id !== style.id
      ) || null,
    [styles, query, selectedStyleToImportFrom]
  );

  const selectedStyles = useSelector(
    (state: RootState) => state.importStylesReducer.selectedStyles
  );

  const isOrderSalesMaterial = useSelector(
    (state: RootState) =>
      state.orderReducer.order?.stylizationType ===
      OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const onSelectStyleHandler = (style: Style) => {
    dispatch(selectStyleForImport(style));

    if (selectedStyleToImportFrom) {
      dispatch(
        setImportStylesModalMeta({
          desc: ImportStylesModalHeaderEnums.SELECT_LINE_ITEMS_DESC,
          style,
          step: ImportStylesModalStepsEnums.SELECT_LINE_ITEMS,
          title: style.name as ImportStylesModalHeaderEnums,
        })
      );
    }
  };

  const onImportStyleSuccessHandler = () => {
    toast.success(
      `You have successfully imported style${
        selectedStyles.length !== 1 ? 's' : ''
      }.`
    );

    dispatch(setStylesModalOpened(null));
    dispatch(getOrderStyles(orderId));
  };

  const onImportStyleClickHandler = () => {
    const mappedStyles = selectedStyles.map(
      (style) =>
        ({
          styleId: style.id,
          lineItems: [],
        } as CheckedStyle)
    );

    if (importOrderId) {
      setCopyingStylesLoading(true);
      dispatch(
        copySelectedItems(
          {
            newOrderId: orderId,
            orderId: importOrderId,
            styles: mappedStyles,
            isOrderSalesMaterial,
          },
          onImportStyleSuccessHandler,
          setCopyingStylesLoading
        )
      );
    }
  };

  useEffect(() => {
    if (importOrderId) {
      setStylesLoading(true);
      dispatch(getOrderStyles(importOrderId, setStylesLoading));
    }
  }, [importOrderId]);

  useEffect(() => {
    return () => {
      dispatch(setStylesForImport(null));
    };
  }, []);

  return (
    <ImportContainer>
      <SearchInput
        data-role="search"
        data-loading={stylesLoading}
        data-test="input-stylesSearch"
        placeholder="Search for style..."
        ref={searchInputRef}
        onSearchQueryChanged={(e) => onChange(e.target.value)}
      />

      <Spacer h="15px" />

      <ScrollbarsCustom
        autoHide
        autoHeight
        autoHeightMin={500}
        autoHeightMax={500}
        autoHideTimeout={500}
        autoHideDuration={300}
      >
        <ImportStyleSelectContainer>
          {stylesLoading && <Loader noSpacing />}

          {!stylesLoading && !filteredStyles?.length && (
            <P>There are no styles in this order.</P>
          )}

          {filteredStyles?.map((style) => {
            const isSelected = selectedStyles.some(
              (selectedStyle) => selectedStyle.id === style.id
            );
            return (
              <Wrapper withTooltip={!!style.override}>
                <OrderStyle
                  key={style.id}
                  style={style}
                  expandable={false}
                  selected={isSelected}
                  noActions
                  onSelect={style.override ? undefined : onSelectStyleHandler}
                  marginBottom={style.override ? 0 : 16}
                />

                {style.override && (
                  <Tooltip position="bottom">
                    Overriden Style cannot be selected for import.
                  </Tooltip>
                )}
              </Wrapper>
            );
          })}
        </ImportStyleSelectContainer>
      </ScrollbarsCustom>

      <Spacer h="20px" />

      {!selectedStyleToImportFrom && (
        <Wrapper flex justifyEnd>
          <ButtonPrimary
            disabled={!selectedStyles.length || copyingStylesLoading}
            onClick={onImportStyleClickHandler}
          >
            Import Style{selectedStyles.length !== 1 && 's'}
            {selectedStyles.length > 0 ? ` (${selectedStyles.length})` : ''}
            <Loader
              size={16}
              insideButton
              noSpacing
              hidden={!copyingStylesLoading}
            />
          </ButtonPrimary>
        </Wrapper>
      )}
    </ImportContainer>
  );
};

export default ImportStyleSelect;
