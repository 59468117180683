import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  Control,
  Controller,
  UseFormClearErrors,
  useFormContext,
} from 'react-hook-form';

import {
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { OrderFormFields } from 'order/wizard/orderForm/OrderForm';

import { CreatableStyledSelect } from 'shared/components/Select';
import { getOrderJobs } from 'order/store/orderActions';
import { orderJobValidation } from 'shared/validations/validations';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import UtilService from 'shared/services/util.service';
import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';
import { getInitDealership } from 'auth/store/authActions';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

interface OrderJobProps {
  control: Control<OrderFormFields>;
  onSelectJob: Dispatch<SetStateAction<SelectOptionProps | null>>;
  clearFormErrors: UseFormClearErrors<OrderFormFields>;
  disabled: boolean;
  selectedJob: SelectOptionProps | null;
}

interface SearchJobsOptions {
  name: string;
  page: number;
  append: boolean;
  resetPagination?: boolean;
}

const OrderJob: FC<OrderJobProps> = ({
  control,
  onSelectJob,
  clearFormErrors,
  disabled,
  selectedJob,
}) => {
  const dispatch = useAppDispatch();

  const {
    dealershipId,
    csrSelectedDealershipId,
    isCSRSelectedDealershipDirty,
  } = useContext(OrderFormContext);

  const methodsContext = useFormContext<OrderFormFields>();

  const [searchedJob, setSearchedJob] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  const [jobsPerPage] = useState(8);

  const [jobPage, setJobPage] = useState(1);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const [searchedJobs, setSearchedJobs] =
    useState<Promise<SelectOptionProps[]>>();

  const jobs = useSelector((state: RootState) => state.orderReducer.jobs);

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const searchJobs = (options: SearchJobsOptions) => {
    if (options.resetPagination) {
      setJobPage(1);
    }

    const searchJobsPromise = new Promise<SelectOptionProps[]>((resolve) => {
      if (dealershipId) {
        dispatch(
          getOrderJobs(
            {
              dealershipId,
              name: options.name,
              itemsPerPage: jobsPerPage,
              page: options.resetPagination ? 1 : options.page,
              append: options.append,
            },
            resolve
          )
        );
      }
    });

    setSearchedJobs(searchJobsPromise);
  };

  const onCreateJobOptionHandler = (inputValue: string) => {
    const newJobOption = UtilService.createOption(inputValue);
    onSelectJob(newJobOption);
    clearFormErrors('jobId');

    searchJobs({
      name: '',
      page: 1,
      append: false,
      resetPagination: true,
    });
  };

  const onSearchJobHandler = (
    option: SelectOptionProps | null,
    triggeredAction: { action: string },
    onChange: (value: string) => void
  ) => {
    onChange(option ? option.value : '');

    if (option?.hasCreated) {
      onSelectJob(option);
    }

    if (triggeredAction.action === 'clear') {
      searchJobs({
        name: '',
        page: 1,
        append: false,
        resetPagination: true,
      });

      onSelectJob(null);
      setSearchedJob('');
    }
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (jobs?.hasNextPage && dealershipId) {
      searchJobs({
        name: searchedJob,
        page: jobPage,
        append: true,
      });
    }
  }, [jobPage]);

  useEffect(() => {
    if (dealershipId) {
      searchJobs({
        name: searchedJob,
        page: 1,
        append: false,
        resetPagination: true,
      });
    }
  }, [searchedJob]);

  useEffect(() => {
    if (dealershipId && isMounted) {
      searchJobs({
        name: '',
        page: 1,
        append: false,
        resetPagination: true,
      });
    }
  }, [dealershipId, isMounted]);

  useEffect(() => {
    if (order?.job) {
      onSelectJob(
        UtilService.mapJobsToSelectOptions(order.job) as SelectOptionProps
      );

      onSelectJob(
        UtilService.mapJobsToSelectOptions(order.job) as SelectOptionProps
      );
    }
  }, [order]);

  // when CSR changes the dealership
  useEffect(() => {
    if (
      isCSRSelectedDealershipDirty &&
      (selectedJob?.hasCreated || csrSelectedDealershipId === null)
    ) {
      setSearchedJob('');
    }
  }, [csrSelectedDealershipId]);

  useEffect(() => {
    dispatch(getInitDealership(isUserDealerOrSalesRep));
  }, []);

  return (
    <Controller
      control={control}
      name="jobId"
      rules={orderJobValidation({ required: true })}
      render={({ field: { onChange, ref } }) => (
        <CreatableStyledSelect
          ref={ref}
          isClearable
          createOptionPosition="first"
          isDisabled={disabled || !dealershipId}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            // Override search functionality - dirty hack
            // since load options search is not working properly
            const isBackSpace = e.key === 'Backspace';

            if (e.key.length > 1 && !isBackSpace) return;

            setSearchedJob((prevValue) =>
              isBackSpace ? prevValue.slice(0, -1) : `${prevValue}${e.key}`
            );
          }}
          inputValue={searchedJob}
          className={methodsContext.formState.errors.jobId ? 'invalid' : ''}
          loadOptions={() => searchedJobs}
          noOptionsMessage={() => 'No jobs available'}
          onCreateOption={onCreateJobOptionHandler}
          placeholder="Select existing or create a new job"
          value={selectedJob}
          defaultOptions={
            jobs && UtilService.mapJobsToSelectOptions(jobs.items)
          }
          onMenuScrollToBottom={() => setJobPage((prevState) => prevState + 1)}
          onMenuClose={() => {
            searchJobs({
              name: '',
              page: 1,
              append: false,
              resetPagination: true,
            });

            setSearchedJob('');
          }}
          onChange={(
            option: SelectOptionProps | null,
            triggeredAction: { action: string }
          ) => onSearchJobHandler(option, triggeredAction, onChange)}
        />
      )}
    />
  );
};

export default OrderJob;
